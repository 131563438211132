import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import { entityKeyName } from '@vue-storefront/core/lib/store/entities'
import { Logger } from '@vue-storefront/core/lib/logger'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import slugifyCategories from '@vue-storefront/core/modules/catalog/helpers/slugifyCategories'
import NavigationState from '../types/NavigationState';

const mutations: MutationTree<NavigationState> = {
  [types.NAVIGATION_UPD_CATEGORIES] (state, categories) {
    const catCollection = StorageManager.get('navigation')

    for (let category of categories.items) {
      category = slugifyCategories(category)
      const catExist = state.navigation_list.find(existingCat => existingCat.id === category.id)

      if (!catExist) {
        state.navigation_list.push(category)
      }

      if (!categories.includeFields) {
        try {
          catCollection
            .setItem(entityKeyName('slug', category.slug.toLowerCase()), category)
            .catch(reason => Logger.error(reason, 'category'))

          catCollection
            .setItem(entityKeyName('id', category.id), category)
            .catch(reason => Logger.error(reason, 'category'))
        } catch (e) {
          Logger.error(e, 'category')()
        }
      }
    }

    state.navigation_list.sort((catA, catB) => {
      if (catA.position && catB.position) {
        if (catA.position < catB.position) return -1
        if (catA.position > catB.position) return 1
      }
      return 0
    })
  }
}

export default mutations
