import { GetterTree } from 'vuex';
import CartState from '@vue-storefront/core/modules/cart/types/CartState';
import RootState from '@vue-storefront/core/types/RootState';
import { onlineHelper } from '@vue-storefront/core/helpers';
import calculateTotals from '../../helpers/calculateTotals';

const getters: GetterTree<CartState, RootState> = {
  getTotals: ({ cartItems, platformTotalSegments }, getters) =>
    (platformTotalSegments && onlineHelper.isOnline)
      ? platformTotalSegments
      : calculateTotals(getters.getFirstShippingMethod, getters.getFirstPaymentMethod, cartItems)
};

export default getters;
