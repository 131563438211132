import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from 'core/types/RootState'
import NavigationState from '../types/NavigationState';

export const NavigationModule: Module<NavigationState, RootState> = {
  namespaced: true,
  state: {
    navigation_list: []
  },
  actions,
  getters,
  mutations
}
