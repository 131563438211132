import config from 'config'
import store from '@vue-storefront/core/store'
import { optionLabel } from '@vue-storefront/core/modules/catalog/helpers/optionLabel'
import { productJsonLd } from '@vue-storefront/core/helpers'
import dayjs from 'dayjs'

export const getAttrOptionLabel = (attributeKey, optionId) => {
  return optionLabel(store.state.attribute, { attributeKey: attributeKey, searchBy: 'code', optionId: optionId })
}

export const getProduct = (item, list = null, cat = null, position = null) => {
  const {sku, category, parentSku: parent, options} = item
  let product = {}
  const attributeMap: string[] | Record<string, any>[] = config.googleTagManager.product_attributes
  attributeMap.forEach(attribute => {
    const isObject = typeof attribute === 'object'
    let attributeField = isObject ? Object.keys(attribute)[0] : attribute
    let attributeName = isObject ? Object.values(attribute)[0] : attribute
    if (item.hasOwnProperty(attributeField) || product.hasOwnProperty(attributeName)) {
      const value = item[attributeField] || product[attributeName]
      if (value) {
        if (attributeName === 'variant' || attributeName === 'brand') {
          product[attributeName] = getAttrOptionLabel(attributeField, value)
        } else {
          product[attributeName] = value
        }
      }
    }
  })
  if (position) {
    product['position'] = position
  }
  if (list) {
    product['list'] = list
  }
  if (parent) {
    product['sku'] = parent
    product['id'] = parent
    if (options && options.length > 0) {
      product['variant'] = sku
    }
  }
  if (category && category.length > 0) {
    product['category'] = category.slice(-1)[0].name
  }
  if (cat) {
    product['category'] = cat
  }

  let price
  if (item.qty_ordered) {
    price = item.base_price_incl_tax
  } else {
    price = item.base_final_price_incl_tax
  }
  product['price_formatted'] = store.getters['ayko_localisation/localisePrice'](price)
  product['price'] = Number(price).toFixed(2)
  product['localised_price'] = Number(store.getters['ayko_localisation/localisePrice'](price, null, false, true, false)).toFixed(2)

  return product
}

export const getProducts = (items, list = null, cat = null) => {
  let products = []
  items.forEach((item, index) => {
    if (!item.parent_item_id) {
      products.push(getProduct(item, list, cat, index + 1))
    }
  })
  return products
}

export const getBwRating = (reviews) => ({
  best: Math.max.apply(null, reviews.map(review => Math.max.apply(null, review.ratings.map(rating => rating.percent)))),
  worst: Math.min.apply(null, reviews.map(review => Math.min.apply(null, review.ratings.map(rating => rating.percent))))
})

export const getRatingValue = (review) => {
  if (typeof review.ratings !== 'undefined') {
    return review.ratings.map(rating => rating.value).reduce((a, b) => a + b) / review.ratings.length
  }
  return null
}
export const getBestRating = (review) => Math.max.apply(null, review.ratings.map(rating => rating.value))

export const getLastReview = (reviews) => {
  let last = reviews[0]
  last.ratingValue = getRatingValue(last)
  last.bestRating = getBestRating(last)
  return last
}

export const getDate = (review) => dayjs(review.created_at).format('DD MMMM YYYY')

export function getRichSnippet () {
  let product = store.getters['product/getCurrentProduct'];
  let data = productJsonLd(product, '', store.state.storeView.i18n.currencyCode, []);
  let productImage = store.getters['product/getProductGallery'][0];
  data = {
    ...data,
    ...{
      brand: {
        '@type': 'Brand',
        name: getAttrOptionLabel('manufacturer', product.manufacturer)
      },
      gtin: product.v_gs_gtin || product.v_gs_mpn || product.ean || product.sku,
      image: productImage ? productImage.src : null,
      description: data.description ? data.description.trim() : data.name
    }
  }
  if (store.state['review'].items.hasOwnProperty('items') && product.reviews) {
    let reviews = store.state['review'].items.items;
    if (reviews.length) {
      data['aggregateRating'] = {
        '@type': 'AggregateRating',
        ratingValue: product.reviews ? product.reviews.rating : null,
        reviewCount: product.reviews ? product.reviews.count : 0,
        bestRating: product.reviews ? getBwRating(reviews).best : null,
        worstRating: product.reviews ? getBwRating(reviews).worst : null
      }
      data['review'] = {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: getLastReview(reviews).ratingValue,
          bestRating: getLastReview(reviews).bestRating
        },
        author: {
          '@type': 'Person',
          name: getLastReview(reviews).nickname
        }
      }
    }
  }
  data.offers.url = product.canonical_url;
  data.offers['priceValidUntil'] = product.special_to_date || dayjs().add(1, 'year').format('YYYY-MM-DD');
  return JSON.stringify(data);
}
