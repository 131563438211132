import {ActionTree} from 'vuex';
import SchemeState from '../../types/SchemeState';
import RootState from '@vue-storefront/core/types/RootState';
import * as types from './mutation-types';
import {CycleSchemeService} from '../../data-resolver/CycleSchemeService';
import rootStore from '@vue-storefront/core/store';
import i18n from '@vue-storefront/i18n';

function isValidSchemeResponse (schemeResponse) {
  return schemeResponse && 'providerCode' in schemeResponse.data
}

const actions: ActionTree<SchemeState, RootState> = {
  async setAvailableSchemes ({ commit, state }) {
    if (!state.available_schemes.length) {
      CycleSchemeService.getAvailableSchemes().then(schemes => commit(types.SET_AVAILABLE_SCHEMES, schemes))
    }
    if (!state.saved_scheme) {
      const schemeResponse = await CycleSchemeService.getSavedScheme(rootStore.state.user.current.id) as any

      if (isValidSchemeResponse(schemeResponse)) {
        commit(types.SET_SAVED_SCHEME, schemeResponse.data)
      } else {
        return false
      }
    }
  },

  async updateCert ({ commit, dispatch }, { isOnline, schemeCode, ref, rdm, amount }) {
    let schemeResponse;

    if (isOnline) {
      schemeResponse = await CycleSchemeService.processCert(
        rootStore.state.user.current.id,
        schemeCode,
        ref,
        rdm
      )
    } else {
      schemeResponse = await CycleSchemeService.applyOffline(
        rootStore.state.user.current.id,
        schemeCode,
        amount
      )
    }

    dispatch('notification/spawnNotification', {
      action1: { label: i18n.t('OK') },
      message: schemeResponse.message || schemeResponse.data.message,
      type: schemeResponse.status.toLowerCase()
    }, { root: true });

    if (isValidSchemeResponse(schemeResponse)) {
      commit(types.SET_SAVED_SCHEME, schemeResponse.data)
    } else {
      return false
    }
  }
};

export default actions;
