import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import BootsgridVfinanceState from '../types/BootsgridVfinanceState'

const mutations: MutationTree<BootsgridVfinanceState> = {
  [types.BOOTSGRID_VFINANCE_UPD_VFINANCE] (state, financeProducts) {
    state.financeProducts = financeProducts
  }
}

export default mutations
