import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { CatalogModule } from '@vue-storefront/core/modules/catalog'
import { CatalogNextModule } from '@vue-storefront/core/modules/catalog-next'
import { AykoNavigationModule } from './ayko/navigation'
import { CartModule } from '@vue-storefront/core/modules/cart'
import { CheckoutModule } from '@vue-storefront/core/modules/checkout'
import { ExternalCheckout } from './external-checkout'
import { CompareModule } from '@vue-storefront/core/modules/compare'
import { WishlistModule } from '@vue-storefront/core/modules/wishlist'
import { NotificationModule } from '@vue-storefront/core/modules/notification'
import { UrlModule } from '@vue-storefront/core/modules/url'
import { BreadcrumbsModule } from '@vue-storefront/core/modules/breadcrumbs'
import { UserModule } from '@vue-storefront/core/modules/user'
import { CmsModule } from '@vue-storefront/core/modules/cms'
// import { GoogleTagManagerModule } from './google-tag-manager'
import { CoreConfigModule } from './core-config'
import { CurrencyRatesModule } from './currency-rates'
import { HotjarModule } from './hotjar'
import { AmpRendererModule } from './amp-renderer'
import { PaymentBackendMethodsModule } from './payment-backend-methods'
import { CreatePassword } from './create-password'
import { NewsletterModule } from '@vue-storefront/core/modules/newsletter'
import { BikeProduct } from './bikebuilder-product'
import { BikeBuilderCart } from './bikebuilder-cart'
import { BikeBuilderTax } from './bikebuilder-tax'
import { EposModule } from './epos'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { AykoGoogleTagManagerModule } from './ayko/google-tag-manager'
import { AykoLocalisationModule } from './ayko/localisation'
import { BootsgridVfinanceModule } from './bootsgrid-vfinance'
import { ReviewModule } from '@vue-storefront/core/modules/review'
import { extendMappingFallback } from 'vsf-mapping-fallback'
import { tap } from 'vsf-mapping-fallback/builtin'
import { forRedirect } from './ayko/url-rewrite/mappingFallback'
import { Wordpress } from './vsf-wordpress'
import { EstimateDeliveryModule } from './estimate-delivery'
import { ClearCache } from './clear-cache'
import { AykoPacModule } from './ayko/pac-page'
import { cycleToWork } from './cycletowork'

const extendUrlModule = extendMappingFallback(
  forRedirect, tap
)

// TODO:distributed across proper pages BEFORE 1.11
export function registerClientModules () {
  registerModule(UrlModule)
  registerModule(CatalogModule)
  registerModule(extendUrlModule)
  registerModule(CoreConfigModule)
  registerModule(CurrencyRatesModule)
  registerModule(AykoNavigationModule)
  registerModule(CheckoutModule)
  registerModule(AykoLocalisationModule)
  registerModule(ExternalCheckout) // To External Checkout
  registerModule(CartModule)
  registerModule(PaymentBackendMethodsModule)
  registerModule(WishlistModule) // Trigger on wishlist icon click
  registerModule(NotificationModule)
  registerModule(UserModule) // Trigger on user icon click
  registerModule(CatalogNextModule)
  registerModule(CompareModule)
  registerModule(BreadcrumbsModule)
  // registerModule(GoogleTagManagerModule)
  registerModule(AmpRendererModule)
  registerModule(CmsModule)
  registerModule(NewsletterModule)
  registerModule(HotjarModule)
  registerModule(BootsgridVfinanceModule)
  registerModule(ReviewModule)
  registerModule(BikeProduct)
  registerModule(BikeBuilderCart)
  registerModule(BikeBuilderTax)
  registerModule(EposModule)
  registerModule(Wordpress)
  registerModule(EstimateDeliveryModule)
  registerModule(CreatePassword)
  registerModule(ClearCache)
  registerModule(AykoGoogleTagManagerModule)
  registerModule(AykoPacModule)
  registerModule(cycleToWork)
}

// Deprecated API, will be removed in 2.0
export const registerModules: VueStorefrontModule[] = [
  // Example
];
