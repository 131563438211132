import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'

export const createLoadEstimateDeliveryQuery = (package_id, weight, country_id, website_id) => {
  let query = new SearchQuery();

  query = query.applyFilter({key: 'dest_city', value: {'eq': '0'}})
  query = query.applyFilter({key: 'dest_zip', value: {'eq': '0'}})
  query = query.applyFilter({key: 'mandatory_charge', value: {'eq': '0'}})
  query = query.applyFilter({key: 'compound_charge', value: {'eq': '0'}})

  if (package_id) {
    if (Number.isInteger(package_id)) {
      query = query.applyFilter({key: 'class_id', value: {'in': [package_id.toString()]}})
    } else {
      query = query.applyFilter({key: 'class', value: {'in': [package_id, package_id.toString().toLowerCase()]}})
    }
  }

  if (country_id) {
    query = query.applyFilter({key: 'dest_country_id', value: {'in': [country_id, country_id.toString().toLowerCase()]}})
  }

  if (weight) {
    query = query.applyFilter({key: 'condition_from_value', value: {'lte': weight}})
    query = query.applyFilter({key: 'condition_to_value', value: {'gte': weight}})
  }

  if (website_id) {
    query = query.applyFilter({key: 'website_id', value: {'eq': website_id}})
  }

  return query
}
