import {extendStore} from '@vue-storefront/core/helpers';
import {StorefrontModule} from '@vue-storefront/core/lib/modules';
import {CartModule} from './store/cart';
import {CartBuildModule} from './store/cartBuild';

export const BikeBuilderCart: StorefrontModule = function ({
  store
}) {
  extendStore('cart', CartModule);
  store.registerModule('cartBuild', CartBuildModule);
};
