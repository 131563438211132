import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import RootState from 'core/types/RootState';
import CartState from '@vue-storefront/core/modules/cart/types/CartState';

export const CartModule: Module<CartState, RootState> = {
  actions,
  getters,
  mutations
};
