import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from 'core/types/RootState'
import LocalisationState from '../types/LocalisationState'

export const LocalisationModule: Module<LocalisationState, RootState> = {
  namespaced: true,
  state: {
    country: '',
    currency: '',
    currencySign: ''
  },
  actions,
  getters,
  mutations
}
