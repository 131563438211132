import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import LocalisationState from '../types/LocalisationState'
import Cookies from 'js-cookie'
import config from 'config'
import {Logger} from '@vue-storefront/core/lib/logger'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

type CookieValue = { country: { iso_code: string } };

function setEwsCountryCookie(country) {
  const cookieValue: CookieValue = {
    country: { iso_code: country }
  };

  const value = JSON.stringify(cookieValue);

  const storeCode = currentStoreView().storeCode;
  const domain = storeCode === 'de_de_de' ? config.esw.cookieDomainDe : config.esw.cookieDomain;

  Cookies.set('esw-location', value, {
    expires: Number(config.ayko.localisation.cookie.expires),
    domain
  });
}

function setEwsCurrencyCookie(currency) {
  const storeCode = currentStoreView().storeCode;
  const domain = storeCode === 'de_de_de' ? config.esw.cookieDomainDe : config.esw.cookieDomain;
  Cookies.set('esw-currency', currency, {
    expires: Number(config.ayko.localisation.cookie.expires),
    domain
  });
}

const mutations: MutationTree<LocalisationState> = {
  [types.LOCALISATION_UPD_COUNTRY] (state, { country, updateCookie }) {
    state.country = country
    if (updateCookie) {
      setEwsCountryCookie(country)
    }
    Logger.debug('Localisation State: ' + JSON.stringify(state), 'ayko_localisation')()
  },
  [types.LOCALISATION_UPD_CURRENCY] (state, { currency, updateCookie }) {
    state.currency = currency
    const currencyConfig = config.ayko.localisation.availableCurrencies.find(item => item.name === state.currency)
    state.currencySign = currencyConfig.currencySign
    if (updateCookie) {
      setEwsCurrencyCookie(currency);
    }
    Logger.debug('Localisation State: ' + JSON.stringify(state), 'ayko_localisation')()
  }
}

export default mutations
