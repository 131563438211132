import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';

export const createLoadEposQuery = (store_id) => {
  let query = new SearchQuery();

  if (store_id) {
    query = query.applyFilter({key: 'store_id', value: {'eq': store_id}});
  }

  return query;
}
