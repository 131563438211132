import { Module } from 'vuex'
import actions from './actions'
import RootState from '@vue-storefront/core/types/RootState';
import CreatePasswordState from '../types/CreatePasswordState';

export const createPasswordStore: Module<CreatePasswordState, RootState> = {
  namespaced: true,
  state: {},
  actions
}
