import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import CoreConfigState from '../types/CoreConfigState'

const mutations: MutationTree<CoreConfigState> = {
  [types.CORE_CONFIG_UPD_CONFIG_PATHS] (state, items) {
    for (let config of items) {
      const configExists = state.items.find(
        existingConfig => (
          existingConfig.path === config.path &&
          existingConfig.scope_id === config.scope_id &&
          existingConfig.scope === config.scope
        )
      )
      if (!configExists) {
        state.items.push(config)
      }
    }
  }
}

export default mutations
