export const PRICE_RANGE_MULTIPLIER = 4;
export const MIN_DEPOSIT_PERCENTAGE = 0;
export const MAX_DEPOSIT_PERCENTAGE = 50;

export const getMinSpendLimit = (limits: number[]) => {
  return limits.sort((a, b) => b - a)[0];
}
export const depositAmount = (price, apr) => {
  // TODO get minimum deposit percentage from coreConfigData - also create this in Magento
  return (price / 100) * apr;
}
export const loanAmount = (price, apr, deposit = null) => {
  deposit = deposit === null ? depositAmount(price, apr) : deposit;
  return price - deposit;
}
export const monthlyAmount = (price, apr, v12_interest, deposit = null) => {
  deposit = deposit === null ? depositAmount(price, apr) : deposit;
  let v12_loan_amt = price - deposit;
  return v12_loan_amt * v12_interest;
}
export const totalAmountRepayable = (price, apr, months, v12_interest, deposit = null) => {
  deposit = deposit === null ? depositAmount(price, apr) : deposit;
  let v12_loan_amt = price - deposit;
  let v12_monthly_payment = v12_loan_amt * v12_interest;
  return v12_monthly_payment * months;
}
export const maxMonthPlanInterest = (financeProducts) => {
  if (!financeProducts.length) {
    return [];
  }
  const highestMonths = financeProducts.reduce((a, b) => a.months > b.months ? a : b).months;
  const filterByHighestMonths = financeProducts.filter(
    financeProduct => financeProduct.months === highestMonths
  );
  return filterByHighestMonths.reduce((a, b) =>
    parseInt(a.apr) < parseInt(b.months) ? a : b
  );
}
export const maxMonthPlanInterestWithZeroApr = (financeProducts) => {
  let findTopMonth = [];
  financeProducts.forEach(obj => {
    if (obj.apr === '0') {
      findTopMonth.push(obj);
    }
  });
  return maxMonthPlanInterest(findTopMonth);
}
export const priceNumber = (value) => {
  // eslint-disable-next-line no-useless-escape
  if (typeof value === 'undefined' || value === null || value === '') {
    return 0;
  }
  return value.replace(/[^0-9\.-]+/g, '');
}

export const initialPayment = (loanAmount, months, serviceFee = null, apr = null, deferredPeriod = null) => {
  let initialPayments;
  if (serviceFee != null && apr != null && deferredPeriod != null) {
    let yields = Math.pow(((apr) / 100) + 1, (1.00 / 12));
    let pv = loanAmount - serviceFee;

    if (deferredPeriod > 1) {
      pv = (pv * Math.pow(yields, (deferredPeriod - 1)));
    }
    initialPayments = Math.floor(((0 - pv / ((Math.pow(yields, 0 - months) - 1) / (yields - 1)))) * 100) / 100;
  } else {
    initialPayments = Math.round((loanAmount / months) * 100) / 100;
    if (initialPayments * months < loanAmount) {
      initialPayments += 0.01;
    }
  }
  return initialPayments;
}

export const getFinalPayment = (loanAmount, initialPayments, months) => {
  return (loanAmount - (initialPayments * (months - 1)));
}

const getDocumentFee = (financeProduct, loanAmount) => {
  let documentFee = parseFloat(financeProduct.rb_document_fee) + (loanAmount * parseFloat(financeProduct.rb_document_fee_percentage));
  if (parseFloat(financeProduct.rb_document_fee_minimum) > 0 && documentFee < parseFloat(financeProduct.rb_document_fee_minimum)) {
    documentFee = parseFloat(financeProduct.rb_document_fee_minimum);
  }
  if (parseFloat(financeProduct.rb_document_fee_maximum) > 0 && documentFee > parseFloat(financeProduct.rb_document_fee_maximum)) {
    documentFee = parseFloat(financeProduct.rb_document_fee_maximum);
  }
  return documentFee;
}

export const calculateApr = (loan, instalment, deferred, term) => {
  let result = 0;
  let high = 200;
  let low = 0;
  let n, x, j, q, y, z;

  if (deferred > 1) {
    n = (term + deferred + 1);
  } else {
    n = (term + 1);
  }

  x = 1;

  while (x < 20) {
    result = (high + low) / 2;
    j = Math.pow((1.0000 + result / 100.0000), (1.0000 / 12.0000));
    q = (1.0000 / j);

    if (deferred < 1) {
      y = (instalment * (1.0000 - Math.pow(q, n))) / (1 - q) - instalment;

      z = 0.00;
    } else {
      y = (instalment * (1.0000 - Math.pow(q, n - 1))) / (1 - q) - instalment;

      z = (instalment * (1.0000 - Math.pow(q, deferred))) / (1 - q) - instalment;
    }
    if ((y - z) < loan) {
      high = result;
    } else {
      low = result;
    }
    x++;
  }

  return result;
}

const XIRR = (values, dates, guess) => {
  // Credits: algorithm inspired by Apache OpenOffice

  // Calculates the resulting amount
  var irrResult = function (values, dates, rate) {
    var r = rate + 1;
    var result = values[0];
    for (var i = 1; i < values.length; i++) {
      result += values[i] / Math.pow(r, dates[i].diff(dates[0], 'days') / 365);
    }
    return result;
  };

  // Calculates the first derivation
  var irrResultDeriv = function (values, dates, rate) {
    var r = rate + 1;
    var result = 0;
    for (var i = 1; i < values.length; i++) {
      var frac = dates[i].diff(dates[0], 'days') / 365;
      result -= frac * values[i] / Math.pow(r, frac + 1);
    }
    return result;
  };

  // Check that values contains at least one positive value and one negative value
  var positive = false;
  var negative = false;
  for (var i = 0; i < values.length; i++) {
    if (values[i] > 0) {
      positive = true;
    }
    if (values[i] < 0) {
      negative = true;
    }
  }

  // Return error if values does not contain at least one positive value and one negative value
  if (!positive || !negative) {
    return '#NUM!';
  }

  // Initialize guess and resultRate
  guess = (typeof guess === 'undefined') ? 0.1 : guess;
  var resultRate = guess;

  // Set maximum epsilon for end of iteration
  var epsMax = 1e-10;

  // Set maximum number of iterations
  var iterMax = 50;

  // Implement Newton's method
  var newRate, epsRate, resultValue;
  var iteration = 0;
  var contLoop = true;
  do {
    resultValue = irrResult(values, dates, resultRate);
    newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
    epsRate = Math.abs(newRate - resultRate);
    resultRate = newRate;
    contLoop = (epsRate > epsMax) && (Math.abs(resultValue) > epsMax);
  } while (contLoop && (++iteration < iterMax));

  if (contLoop) {
    return '#NUM!';
  }

  // Return internal rate of return
  return resultRate;
}

const calculateAprFromIrr = (loan, monthlyinstalment, loanTerm, documentfee, documentfeecollectionmonth) => {
  let startDate = new Date();
  let incomeTable = [];
  let dateTable = [];

  if (documentfeecollectionmonth === 0) {
    incomeTable.push(loan * -1 + documentfee);
  } else {
    incomeTable.push(loan * -1);
  }
  dateTable.push(startDate);

  for (var i = 1; i <= loanTerm; i++) {
    var nextDate = startDate;
    dateTable.push(nextDate);

    if ((i - 1) === documentfeecollectionmonth && documentfeecollectionmonth > 0) {
      incomeTable.push(parseFloat(monthlyinstalment + documentfee));
    } else {
      incomeTable.push(parseFloat(monthlyinstalment));
    }
  }
  var r = XIRR(incomeTable, dateTable, 0.1);

  return Math.round(r * 10000) / 100;
}

export const calculateFinanceParameter = (financeProduct, cashPrice, deposit) => {
  let apr, months, monthlyRate, calculatedApr, documentFee, initialPayments, finalPayment, balancePayable, loanAmount, serviceFee;
  apr = parseFloat(financeProduct.apr);
  monthlyRate = financeProduct.rb_monthly_rate ? parseFloat(financeProduct.rb_monthly_rate) : 0.00;
  months = parseFloat(financeProduct.months);
  serviceFee = financeProduct.rb_service_fee ? parseFloat(financeProduct.rb_service_fee) : 0.00;
  cashPrice = parseFloat(cashPrice);
  deposit = parseFloat(deposit);
  loanAmount = cashPrice - deposit;
  documentFee = getDocumentFee(financeProduct, loanAmount);

  if (monthlyRate === 0) {
    initialPayments = initialPayment(loanAmount, months);
    finalPayment = getFinalPayment(loanAmount, initialPayments, months);
    balancePayable = loanAmount;
    calculatedApr = 0;
  } else {
    initialPayments = initialPayment(loanAmount, months, serviceFee, apr, financeProduct.rb_deferred_period);
    finalPayment = initialPayments;
    balancePayable = (initialPayments * months);
    calculatedApr = calculateApr(
      (loanAmount - parseFloat(financeProduct.rb_service_fee)),
      initialPayments,
      parseFloat(financeProduct.rb_deferred_period),
      months
    );
  }

  if (documentFee > 0) {
    calculatedApr = calculateAprFromIrr(
      loanAmount,
      initialPayments,
      months,
      parseFloat(documentFee),
      parseFloat(financeProduct.rb_document_fee_collection_month)
    );
  }

  let interest = (balancePayable - loanAmount);
  let chargeForCredit = (interest + serviceFee + documentFee);
  let amountPayable = (balancePayable + serviceFee + documentFee + deposit);
  let productAvailable = true;
  let availabilityReason = '';
  return {
    initialPayments: initialPayments.toFixed(2),
    finalPayment: finalPayment.toFixed(2),
    balancePayable: balancePayable.toFixed(2),
    interest: interest.toFixed(2),
    chargeForCredit: chargeForCredit.toFixed(2),
    amountPayable: amountPayable.toFixed(2),
    cashPrice: cashPrice.toFixed(2),
    deposit: deposit.toFixed(2),
    loanAmount: loanAmount.toFixed(2),
    months: months,
    monthsDeferred: parseFloat(financeProduct.rb_deferred_period),
    apr: calculatedApr.toFixed(2),
    productAvailable: productAvailable,
    availabilityReason: availabilityReason,
    productId: financeProduct.product_id,
    productGuid: financeProduct.product_guid,
    name: financeProduct.name,
    settlementFee: parseFloat(financeProduct.rb_settlement_fee).toFixed(2),
    serviceFee: serviceFee.toFixed(2),
    documentFee: documentFee.toFixed(2),
    documentFeeMinimum: parseFloat(financeProduct.rb_document_fee_minimum),
    documentFeeMaximum: parseFloat(financeProduct.rb_document_fee_maximum),
    documentFeeCollectionMonth: parseFloat(financeProduct.rb_document_fee_collection_month),
    documentFeePercentage: parseFloat(financeProduct.rb_document_fee_percentage)
  };
}

export const calculateDeposit = (price, financeProducts, deposit, financeProductId) => {
  let currentFinanceLimits;
  let depositAmount = price * deposit / 100;
  let calculatedDeposit;
  financeProducts.forEach((key, index) => {
    if (key.product_id !== financeProductId) {
      return true;
    }
    currentFinanceLimits = key;
    return false;
  });
  if (typeof deposit === 'undefined' || deposit === null || deposit === '' || deposit < 0) {
    return MIN_DEPOSIT_PERCENTAGE;
  } else if (deposit > MAX_DEPOSIT_PERCENTAGE) {
    return MAX_DEPOSIT_PERCENTAGE;
  }
  if (typeof currentFinanceLimits !== 'undefined' && (price - depositAmount) < currentFinanceLimits.rb_min_loan) {
    calculatedDeposit = 100 * (price - currentFinanceLimits.rb_min_loan) / price;
  }
  if (typeof calculatedDeposit !== 'undefined' && calculatedDeposit !== null && deposit !== calculatedDeposit) {
    if (calculatedDeposit === '' || calculatedDeposit < 0) {
      calculatedDeposit = MIN_DEPOSIT_PERCENTAGE;
    } else if (calculatedDeposit > MAX_DEPOSIT_PERCENTAGE) {
      calculatedDeposit = MAX_DEPOSIT_PERCENTAGE;
    }
    return calculatedDeposit;
  }
  return deposit;
}
