import {GetterTree} from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import CoreConfigState from '../types/CoreConfigState'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import {StoreView} from 'core/lib/types';

const getters: GetterTree<CoreConfigState, RootState> = {
  getAllConfigItems: state => state.items,
  getConfigItems: (state, getters) => (path: string, storeView: StoreView) => {
    if (!storeView) {
      storeView = currentStoreView();
    }
    let items = getters.getConfigItemByScope(path, parseInt(storeView.storeId), 'stores');
    if (!items) {
      items = getters.getConfigItemByScope(path, parseInt(storeView.websiteId), 'websites');
    }
    if (!items) {
      items = getters.getConfigItemByScope(path, 0, 'default');
    }

    return items;
  },
  getConfigItem: (state, getters) => (path: string, storeView: StoreView) => {
    if (!storeView) {
      storeView = currentStoreView();
    }
    let item = getters.getConfigItemByScope(path, parseInt(storeView.storeId), 'stores')[0];
    if (!item) {
      item = getters.getConfigItemByScope(path, parseInt(storeView.websiteId), 'websites')[0];
    }
    if (!item) {
      item = getters.getConfigItemByScope(path, 0, 'default')[0];
    }

    return typeof item !== 'undefined' ? item.value : null;
  },
  getConfigItemByScope: (state, getters) => (path: string, scopeId: number, scope: string) => {
    return getters.getAllConfigItems.filter(item => {
      return item.path === path && parseInt(item.scope_id) === scopeId && item.scope === scope;
    });
  }
}

export default getters;
