import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import CreatePasswordState from '../types/CreatePasswordState'
import { CreatePasswordService } from '../data-resolver/CreatePasswordService'

const actions: ActionTree<CreatePasswordState, RootState> = {
  /**
   * Create new password for provided email with resetToken
   * We could receive resetToken by running user.resetPassword action
   */
  async createPassword (context, { email, newPassword, resetToken }) {
    return CreatePasswordService.createPassword(email, newPassword, resetToken).then(res => res)
  },
  async checkResetToken (context, {customerId, resetToken}) {
    return CreatePasswordService.checkResetToken(customerId, resetToken).then(res => res);
  }
}

export default actions
