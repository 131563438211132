import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'

export const createLoadCurrencyRatesQuery = (currencySymbols) => {
  let query = new SearchQuery()
  let isCustomized = false

  if (currencySymbols) {
    if (typeof currencySymbols === 'string' || currencySymbols instanceof String) {
      currencySymbols = [ currencySymbols ]
    }
    query = query.applyFilter({ key: 'currency_symbol', value: { 'in': currencySymbols } })
    isCustomized = true
  }
  return { query, isCustomized }
}
