import * as types from './mutation-types';
import { pastDateOptionIds } from '../../../bikebuilder/helpers/optionHelpers';
import remove from 'lodash-es/remove'
import {ActionTree} from 'vuex';
import RootState from 'core/types/RootState';
import CartBuildState from '../../types/CartBuildState';

const actions: ActionTree<CartBuildState, RootState> = {
  setBike ({ commit }, bike) {
    commit(types.SET_BIKE, bike);
  },
  setProduct ({ commit }, bike) {
    commit(types.SET_PRODUCT, bike);
  },
  loadSections ({ commit }, sections) {
    commit(types.SET_SECTIONS, sections);
  },
  loadSectionGroups ({ commit }, sectionGroups) {
    commit(types.SET_SECTION_GROUPS, sectionGroups);
  },
  async setOptions ({ dispatch, commit }, options) {
    const filteredOptions = remove(
      options,
      option => !pastDateOptionIds(options).includes(option.id)
    );
    
    commit(types.SET_OPTIONS, filteredOptions);
  },
  setSelectedOptions ({ commit, getters }, productOptions) {
    const options = productOptions.map(option => {
      return {
        id: option.option_id,
        option: getters.getCartOptionById(option.option_id),
        userQty: option.option_qty
      };
    });
    commit(types.SET_SELECTED_OPTIONS, options);
  }
};

export default actions;
