import { ActionTree } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import RootState from '@vue-storefront/core/types/RootState'
import EstimateDeliveryState from '../types/EstimateDeliveryState'
import * as types from './mutation-types'
import { createLoadEstimateDeliveryQuery } from '../helpers/createLoadEstimateDeliveryQuery'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import config from 'config'
import {currentStoreView} from '@vue-storefront/core/lib/multistore';

const actions: ActionTree<EstimateDeliveryState, RootState> = {
  async list ({ commit, state, dispatch }, {
    package_id = null,
    weight = 0,
    country_id = currentStoreView().tax.defaultCountry,
    website_id = null,
    start = 0,
    size = 5,
    entityType = 'vortex_shipping_rate',
    sort = 'price',
    excludeFields = null,
    includeFields = null,
    skipCache = false,
    updateState = true
  }) {
    if (typeof weight === 'undefined') {
      weight = 0.00
    }
    if (typeof package_id === 'undefined') {
      package_id = false
    }

    const query = createLoadEstimateDeliveryQuery(package_id, weight, country_id, website_id)

    const cacheKey = package_id + '_' + weight + '_' + country_id + '_' + website_id
    const shouldLoad = skipCache || (!state.items || !state.items.find(cache => (cache.cacheKey === cacheKey && cache.items && cache.items.length)))

    if (shouldLoad) {
      const resp = await quickSearchByQuery({
        query,
        start,
        size,
        entityType,
        sort,
        excludeFields,
        includeFields
      })

      if (updateState) {
        commit(types.ESTIMATE_DELIVERY_UPD_SHIPPING_RATES, { cacheKey: cacheKey, items: resp.items })
      }
    }

    const items = state.items.find(cache => (cache.cacheKey === cacheKey && cache.items && cache.items.length));
    let list = { items: [], total: 0 }
    if (items && items.length) {
      list = { items: items, total: items.length }
    }

    if (updateState) {
      EventBus.$emit('shipping-rates-after-list', { query: query, sort, size, start, list: list })
    }

    return list
  }
}

export default actions
