import { MutationTree } from 'vuex';
import * as types from './mutation-types';
import EposState from '../types/EposState';

const mutations: MutationTree<EposState> = {
  [types.SET_POS_DATA] (state, posData) {
    state.posData = posData;
  }
};

export default mutations;
