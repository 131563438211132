import UserAgent from '../types/UserAgent';
import { isServer } from '@vue-storefront/core/helpers';

export function getRibbleUserAgent (): UserAgent {
  if (isServer) {
    return null;
  }
  const ribbleUaIdentifier = 'Ribble-';
  const ua = navigator.userAgent;
  const ribbleUa = ua.split(' ').find((part) => {
    return part.startsWith(ribbleUaIdentifier);
  });
  if (!ribbleUa) {
    return null;
  }
  const ribbleUaArray = ribbleUa.replace('Ribble-', '').split('-');
  return {
    device: ribbleUaArray[0].toLowerCase() || null,
    retailStore: ribbleUaArray[1].toLowerCase() || null,
    sku: ribbleUaArray[2].toLowerCase() || null,
    isCustomised: ribbleUaArray[3].toLowerCase() || null
  };
}
