import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { htmlDecode } from '@vue-storefront/core/filters'
import { getProducts } from './helpers/product'
import { once, isServer } from '@vue-storefront/core/helpers'
import { afterRegistration } from './hooks/afterRegistration'
import { AykoGoogleTagManagerStore } from './store'
import { afterEach } from './hooks/afterEach'
const KEY = 'ayko_gtm';

export function trackProducts (payload) {
  Vue.gtm.trackEvent({
    event: 'productImpression',
    eventLabel: htmlDecode(payload.label),
    category: payload.category,
    ecommerce: {
      currencyCode: currentStoreView().i18n.currencyCode,
      impressions: getProducts(payload.products || payload.items, payload.list, payload.category)
    }
  })
}

export const AykoGoogleTagManagerModule: StorefrontModule = function ({store, router, appConfig}) {
  // @ts-ignore
  if (!!currentStoreView().googleTagManager.id && !isServer) {
    store.registerModule(KEY, AykoGoogleTagManagerStore)
    once('__VUE_EXTEND_GTM__', () => {
      Vue.use(VueGtm, {
        // @ts-ignore
        enabled: !!currentStoreView().googleTagManager.id,
        // @ts-ignore
        id: currentStoreView().googleTagManager.id,
        debug: process.env.VS_ENV !== 'prod',
        vueRouter: router,
        trackOnNextTick: true
      })
    })
    afterRegistration(appConfig, store)
    router.afterEach(afterEach)
  }
}
