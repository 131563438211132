import { TaskQueue } from '@vue-storefront/core/lib/sync';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import config from 'config';
import Scheme from '../types/Scheme';
import SavedScheme from '../types/SavedScheme';

const payload = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  mode: 'cors'
}
const getAvailableSchemes = async (): Promise<Scheme[]> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cycleToWork.endpoint.available_schemes),
    payload: payload,
    silent: true
  }).then(({ result }) => result[0].data.items);

const getSavedScheme = async (customerId): Promise<SavedScheme> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cycleToWork.endpoint.saved_scheme + '/' + customerId),
    payload: payload,
    silent: true
  }).then(({ result }) => result[0].code === 200 ? result[0] : false);

const processCert = async (customerId, schemeCode, ref, rdm): Promise<any> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cycleToWork.endpoint.process_cert),
    payload: {...payload, ...{method: 'POST', body: JSON.stringify({customerId: customerId, schemeCode: schemeCode, ref: ref, rdm: rdm})}},
    silent: true
  }).then(({ result }) => {
    return typeof result[0] === 'object' ? result[0] : {message: result, status: 'error'}
  });

const applyOffline = async (customerId, schemeCode, amount): Promise<any> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cycleToWork.endpoint.apply_offline),
    payload: {...payload, ...{method: 'POST', body: JSON.stringify({customerId: customerId, schemeCode: schemeCode, amount: amount})}},
    silent: true
  }).then(({ result }) => typeof result[0] === 'object' ? result[0] : {message: result, status: 'error'});

export const CycleSchemeService = {
  getAvailableSchemes,
  getSavedScheme,
  processCert,
  applyOffline
}
