import {ActionTree} from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import createNavigationListQuery from '../helpers/createNavigationListQuery'
import config from 'config'
import * as types from './mutation-types'
import NavigationState from '../types/NavigationState';
import { isServer } from '@vue-storefront/core/helpers'
import Vue from 'vue'

const actions: ActionTree<NavigationState, RootState> = {
  async navigation (
    {commit, state, dispatch},
    {
      parent = null,
      key = null,
      value = null,
      level = null,
      onlyActive = true,
      onlyNotEmpty = false,
      onlyMenu = false,
      size = 4000,
      start = 0,
      sort = 'top_menu_insert_position:asc',
      includeFields = config.entities.optimizeoptimize ? config.entities.category.navigation.includeFields : null,
      excludeFields = config.entities.optimize ? config.entities.category.navigation.excludeFields : null,
      skipCache = false,
      updateState = true
    }
  ) {
    const {searchQuery, isCustomizedQuery} = createNavigationListQuery({
      parent,
      level,
      key,
      value,
      onlyActive,
      onlyNotEmpty,
      onlyMenu
    })
    const shouldLoadCategories = skipCache || ((!state.navigation_list || state.navigation_list.length === 0) || isCustomizedQuery)

    if (shouldLoadCategories) {
      const resp = await quickSearchByQuery({
        entityType: 'category',
        query: searchQuery,
        sort,
        size,
        start,
        includeFields,
        excludeFields
      })

      if (updateState) {
        await dispatch('registerCategoryMapping', {categories: resp.items})

        commit(types.NAVIGATION_UPD_CATEGORIES, {...resp, includeFields, excludeFields})
        EventBus.$emit('navigation-after-list', {query: searchQuery, sort, size, start, list: resp})
      }

      if (isServer && resp.items && resp.items.length) {
        Vue.prototype.$cacheTags.add(`C`)
        resp.items.forEach(category => {
          Vue.prototype.$cacheTags.add(`C${category.id}`)
        })
      }
      return resp
    }

    const list = {items: state.navigation_list, total: state.navigation_list.length}

    if (updateState) {
      EventBus.$emit('navigation-after-list', {query: searchQuery, sort, size, start, list})
    }

    return list
  }
}

export default actions
