import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { PacPageState } from '../types/PacPageState'
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'
import * as types from './mutation-types'

const actions: ActionTree<PacPageState, RootState> = {
  async initPac ({ commit, dispatch }, { currentCategory }) {
    let landingCatIds = []
    let contentCatIds = []

    if (currentCategory.landing_cat_ids) {
      landingCatIds = currentCategory.landing_cat_ids.split(',').map(Number)
    }

    if (currentCategory.content_cat_ids) {
      contentCatIds = currentCategory.content_cat_ids.split(',').map(Number)
    }

    commit(types.PAC_UPD_LANDING_CATEGORIES, landingCatIds)
    commit(types.PAC_UPD_CONTENT_CATEGORIES, contentCatIds)

    const categoriesToLoad = [...landingCatIds, ...contentCatIds]

    let categories = await dispatch('category/list', {
      key: 'id',
      value: categoriesToLoad
    }, { root: true })

    commit(types.PAC_UPD_CATEGORIES, categories.items)

    const contentCategories = categories.items.filter(e => contentCatIds.includes(e.id) && e.bestseller_products)

    const bestsellerProducts = {}

    for (const category of contentCategories) {
      const productIds = category.bestseller_products.split(',').map(Number);

      // Only load items available for purchase
      let collectProductQuery = prepareRelatedQuery('id', productIds);

      const productsResponse = await dispatch('product/list', {
        query: collectProductQuery,
        size: 6,
        sort: 'best_sellers_index:desc',
        updateState: false
      }, { root: true })

      if (productsResponse.items && productsResponse.items.length) {
        bestsellerProducts[category.id] = productsResponse.items
      }
    }

    commit(types.PAC_UPD_BESTSELLERS, bestsellerProducts)

    return categories
  }
}

export default actions
