import config from 'config'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

const clearCache = async () => {
  // clear localStorage
  if (config.clearCache.enabled) {
    await StorageManager.clear()
  }
  // clear Service Worker
  if (config.clearCache.serviceWorker.enabled) {
    if ('serviceWorker' in navigator) {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName)
        })
      })
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(registration => {
          registration.unregister()
        })
      })
    }
  }
}

export {
  clearCache
}
