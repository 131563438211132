import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'

export const createLoadCoreConfigQuery = (paths) => {
  let query = new SearchQuery()
  let isCustomized = false

  if (paths) {
    if (typeof paths === 'string' || paths instanceof String) {
      paths = [paths]
    }
    query = query.applyFilter({key: 'config_path', value: {'in': paths}})
    isCustomized = true;
  }
  return { query, isCustomized }
}
