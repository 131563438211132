import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import {Module} from 'vuex';
import CartBuildState from '../../types/CartBuildState';

export const CartBuildModule: Module<CartBuildState, any> = {
  namespaced: true,

  state: {
    cartBike: null,
    cartProduct: null,
    cartSectionGroups: null,
    cartSections: null,
    cartOptions: null,
    cartSelectedOptions: null,
    error: null
  },

  actions,
  getters,
  mutations
};
