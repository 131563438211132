import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import EstimateDeliveryState from '../types/EstimateDeliveryState'

const mutations: MutationTree<EstimateDeliveryState> = {
  [types.ESTIMATE_DELIVERY_UPD_SHIPPING_RATES] (state, items) {
    state.items.push(items)
  }
}

export default mutations
