import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { PacPageState } from '../types/PacPageState';

const getters: GetterTree<PacPageState, RootState> = {
  getCategories: state => state.categories,
  getLandingCatIds: state => state.landingCatIds,
  getContentCatIds: state => state.contentCatIds,
  getBestsellerProducts: state => state.bestsellerProducts
}

export default getters;
