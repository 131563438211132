import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import CurrencyRatesState from '../types/CurrencyRatesState'

const mutations: MutationTree<CurrencyRatesState> = {
  [types.CURRENCY_RATES_UPD_RATES] (state, items) {
    for (let currency of items) {
      const currencyExists = state.items.find(
        existingCurrency => (
          existingCurrency.currency_from === currency.currency_from &&
          existingCurrency.currency_to === currency.currency_to
        )
      )
      if (!currencyExists) {
        state.items.push(currency)
      }
    }
  }
}

export default mutations
