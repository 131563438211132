import config from 'config';
import { ActionTree } from 'vuex';
import * as types from './mutation-types';
import EposState from '../types/EposState';
import rootStore from '@vue-storefront/core/store';
import { Logger } from '@vue-storefront/core/lib/logger';
import RootState from '@vue-storefront/core/types/RootState';
import { entityKeyName } from '@vue-storefront/core/lib/store/entities';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import { canCache } from '@vue-storefront/core/modules/catalog/helpers/search';
import { isOnline, quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { createLoadEposQuery } from '../helpers/createLoadEposQuery';

const actions: ActionTree<EposState, RootState> = {

  async loadEposData ({ dispatch }, skipCache = false) {
    // 1. THIS GETS CALLED FIRST ON PAGE LOAD
    Logger.info('Fetching EPOS data asynchronously', 'eposData')();
    EventBus.$emit('epos-before-load', {
      store: rootStore
    });
    const eposData = await dispatch('single', {
      key: 'eposData',
      skipCache
    });
    EventBus.$emitFilter('epos-after-load', {
      store: rootStore,
      eposData
    });

    return eposData;
  },

  async single ({ dispatch, commit }, { key, skipCache = false }) {
    // 2. THIS GETS CALLED SECOND BY loadEposData
    const cacheKey = entityKeyName(key);

    return new Promise((resolve, reject) => {
      const benchmarkTime = new Date();
      const cache = StorageManager.get('eposData');

      const setupEposData = async eposData => {
        commit(types.SET_POS_DATA, eposData);
        return eposData;
      };

      const syncEposData = () => {
        // 7. IF NOTHING IN CACHE FETCH THE DATA USING LIST ACTION
        return dispatch('list', { store_id: null }).then(result => {
          if (result && result.items) {
            const eposData = result.items;
            const returnEposDataNoCacheHelper = eposData => {
              EventBus.$emitFilter('epos-after-single', {
                key,
                eposData
              });
              resolve(setupEposData(eposData));
            };
            // 8. THIS GETS RESOLVED FROM THE PARENT PROMISE
            returnEposDataNoCacheHelper(eposData);
          } else {
            setupEposData(null);
            Logger.info('EPOS query returned empty result', 'eposData', {
              'Search for': key
            })();
            reject(new Error('EPOS query returned empty result'));
          }
        });
      };

      const getEposDataFromCache = () => {
        // 5. THIS GETS THE ITEM IN CACHE
        cache.getItem(cacheKey, (err, res) => {
          // report errors
          if (!skipCache && err) {
            Logger.error(err, 'eposData')();
          }
          if (res !== null) {
            Logger.debug(
              'epos:single - result from localForage (for ' +
                cacheKey +
                '),  ms=' +
                (new Date().getTime() - benchmarkTime.getTime()),
              'eposData'
            )();

            const returnEposDataFromCacheHelper = () => {
              const cachedEposData = setupEposData(res);
              EventBus.$emitFilter('epos-after-single', {
                key: key,
                eposData: cachedEposData
              });
              resolve(cachedEposData);
            };
            returnEposDataFromCacheHelper();
          } else {
            // 6. IF RESPONSE IS NULL NOTHING IN CACHE SO SYNC
            syncEposData();
          }
        });
      };

      if (!skipCache) {
        // 3. THIS GETS RUN IF skipCache OFF
        getEposDataFromCache();
      } else {
        if (!isOnline()) {
          skipCache = false;
        }
        syncEposData();
      }
    });
  },

  async list ({ dispatch }, {
    start = 0,
    size = 20,
    sort = '',
    store_id = null,
    excludeFields = null,
    includeFields = null,
    entityType = 'pos_displaybikes'
  }) {
    const query = createLoadEposQuery(store_id);
    const searchResult = await dispatch('findEposData', {
      query,
      start,
      size,
      sort,
      entityType,
      excludeFields,
      includeFields
    });
    EventBus.$emit('epos-after-list', {
      query,
      start,
      size,
      sort,
      entityType,
      result: searchResult
    });

    return searchResult;
  },

  async findEposData ({ dispatch }, {
    query,
    start = 0,
    size = 1,
    entityType = 'pos_displaybikes',
    sort = '',
    cacheByKey = 'eposData',
    excludeFields = null,
    includeFields = null
  }) {
    const isCacheable = canCache({ includeFields, excludeFields });
    // const {excluded, included} = getOptimizedFields({excludeFields, includeFields})
    const response = await quickSearchByQuery({
      query,
      start,
      size,
      entityType,
      sort,
      excludeFields,
      includeFields
    });
    // 10. CONFIGURE THE ES EPOS DATA
    return dispatch('configureLoadedEpos', {
      eposData: response,
      isCacheable,
      cacheByKey
    });
  },

  async configureLoadedEpos ({ dispatch }, {
    eposData,
    isCacheable,
    cacheByKey
  }) {
    if (isCacheable) {
      // 10. CONFIGURE THE ES EPOS DATA TO SEND TO CACHE
      // store cache only for full loads
      const eposStoreData = eposData.items;
      await dispatch('storeEposToCache', { eposStoreData, cacheByKey });
    }

    return eposData;
  },

  storeEposToCache (context, { eposStoreData, cacheByKey }) {
    // 11. STORE THE EPOS DATA IN CACHE
    // context is required
    const cacheKey = entityKeyName(cacheByKey);
    const cache = StorageManager.get('eposData');

    cache
      .setItem(
        cacheKey,
        eposStoreData,
        null,
        config.eposData.disablePersistentEposCache
      )
      .catch(err => {
        Logger.error('Cannot store cache for ' + cacheKey, err)();
        if (
          err.name === 'QuotaExceededError' ||
          err.name === 'NS_ERROR_DOM_QUOTA_REACHED'
        ) {
          // quota exceeded error
          cache.clear(); // clear eposData cache if quota exceeded
        }
      });
  }
};

export default actions;
