import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import RootState from '@vue-storefront/core/types/RootState';
import CoreConfigState from '../types/CoreConfigState';

export const coreConfigStore: Module<CoreConfigState, RootState> = {
  namespaced: true,
  state: {
    items: []
  },
  actions,
  getters,
  mutations
}
