import { ActionTree } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import RootState from '@vue-storefront/core/types/RootState'
import CurrencyRatesState from '../types/CurrencyRatesState'
import * as types from './mutation-types'
import {createLoadCurrencyRatesQuery} from '../helpers/createLoadCurrencyRatesQuery'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { isServer } from '@vue-storefront/core/helpers'
import Vue from 'vue'

const actions: ActionTree<CurrencyRatesState, RootState> = {
  async list ({ commit, state, dispatch }, {
    currencySymbols = '',
    start = 0,
    size = 1000,
    entityType = 'currency_rates',
    sort = '',
    excludeFields = null,
    includeFields = null,
    skipCache = false,
    updateState = true
  }) {
    const { query, isCustomized } = createLoadCurrencyRatesQuery(currencySymbols)

    const shouldLoadCurrency = skipCache || ((!state.items || state.items.length === 0) || isCustomized)

    if (isServer) Vue.prototype.$cacheTags.add(`currency_rates`)

    if (shouldLoadCurrency) {
      const resp = await quickSearchByQuery({
        query,
        start,
        size,
        entityType,
        sort,
        excludeFields,
        includeFields
      })
      if (updateState) {
        commit(types.CURRENCY_RATES_UPD_RATES, resp.items)
        EventBus.$emit('current-rates-after-list', { query: query, sort, size, start, list: resp })
      }
    }

    const list = { items: state.items, total: state.items.length }

    if (updateState) {
      EventBus.$emit('current-rates-after-list', { query: query, sort, size, start, list: list })
    }

    return list
  },

  getCurrencyRate ({ state }, { currencyFrom, currencyTo }) {
    return state.items.find(rate => rate.currency_from === currencyFrom && rate.currency_to === currencyTo)
  }
}

export default actions
