import {MutationTree} from 'vuex';
import SchemeState from '../../types/SchemeState';
import * as types from './mutation-types';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

const mutations: MutationTree<SchemeState> = {
  [types.SET_AVAILABLE_SCHEMES] (state: SchemeState, schemes) {
    state.available_schemes = schemes;
  },
  [types.SET_SAVED_SCHEME] (state: SchemeState, scheme) {
    state.saved_scheme = scheme;
  }
};

export default mutations;
