import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import config from 'config'
import * as types from './mutation-types'
import LocalisationState from '../types/LocalisationState'
import {Logger} from '@vue-storefront/core/lib/logger'
import {currentStoreView} from '@vue-storefront/core/lib/multistore'

const actions: ActionTree<LocalisationState, RootState> = {
  setDefaultCountry (context, country) {
    Logger.debug('Localisation: setDefaultCountry: ' + country, 'ayko_localisation')()
    if (!context.state.country) {
      if (config.ayko.localisation.availableCountries.find(availableCountry => availableCountry.code === country)) {
        context.commit(types.LOCALISATION_UPD_COUNTRY, { country })
        const shippingDetails = context.rootGetters['checkout/getShippingDetails']
        if (typeof shippingDetails === 'undefined' || shippingDetails.country !== country) {
          context.dispatch('checkout/saveShippingDetails', { ...{}, ...shippingDetails, country: country }, { root: true })
        }
      } else {
        Logger.error('Country not found: setDefaultCountry: ' + country, 'ayko_localisation')()
      }
    }
  },
  setCountry (context, country, updateCookie = true) {
    Logger.debug('Localisation: setCountry: ' + country, 'ayko_localisation')()
    if (config.ayko.localisation.availableCountries.find(availableCountry => availableCountry.code === country)) {
      context.commit(types.LOCALISATION_UPD_COUNTRY, {country, updateCookie})
      context.dispatch('setShippingCountry', country)
    } else {
      Logger.error('Country not found: setShippingCountry: ' + country, 'ayko_localisation')()
    }
  },
  setShippingCountry (context, country) {
    Logger.debug('Localisation: setShippingCountry: ' + country, 'ayko_localisation')()
    if (config.ayko.localisation.availableCountries.find(availableCountry => availableCountry.code === country)) {
      const shippingDetails = context.rootGetters['checkout/getShippingDetails']
      if (typeof shippingDetails.country === 'undefined' || shippingDetails.country !== country) {
        context.dispatch('checkout/saveShippingDetails', { ...{}, ...shippingDetails, country: country }, { root: true })
      }
    } else {
      Logger.error('Country not found: setShippingCountry: ' + country, 'ayko_localisation')()
    }
  },
  setDefaultCurrency (context, currency) {
    Logger.debug('Localisation: setDefaultCurrency: ' + currency, 'ayko_localisation')()
    if (!context.state.currency) {
      if (config.ayko.localisation.availableCurrencies.find(availableCurrency => availableCurrency.name === currency)) {
        context.commit(types.LOCALISATION_UPD_CURRENCY, { currency })
      } else {
        Logger.error('Currency not found: setDefaultCurrency: ' + currency, 'ayko_localisation')()
      }
    }
  },
  setCurrency (context, currency, updateCookie = true) {
    Logger.debug('Localisation: setCurrency: ' + currency, 'ayko_localisation')()
    if (config.ayko.localisation.availableCurrencies.find(availableCurrency => availableCurrency.name === currency)) {
      context.commit(types.LOCALISATION_UPD_CURRENCY, {currency, updateCookie})
    } else {
      Logger.error('Currency not found: setCurrency: ' + currency, 'ayko_localisation')()
    }
  },
  getCurrencyRate ({ dispatch }, currency) {
    return dispatch('currency_rates/getCurrencyRate', {
      currencyFrom: currentStoreView().i18n.baseCurrencyCode,
      currencyTo: currency
    })
  }
}

export default actions
