import dayjs from 'dayjs';
import uniq from 'lodash-es/uniq'
import flatMap from 'lodash-es/flatMap'
import BuilderOption from '../types/BuilderOption';
function highestPriority (options: BuilderOption[]) {
  return options.reduce((prev, next) => (prev.priority < next.priority ? prev : next));
}
function lowestPriority (options: BuilderOption[], priority: number) {
  return options.filter(option => option.priority !== priority);
}
function duplicateParents (options: BuilderOption[]) {
  const lookup = options.reduce((prev, next) => {
    prev[next.product.parentId] = ++prev[next.product.parentId] || 0;
    return prev;
  }, {});
  return options.filter(option => lookup[option.product.parentId]);
}
function getDuplicateIds (options: BuilderOption[]) {
  const duplicateOptions = duplicateParents(options);
  return flatMap(duplicateOptions, (option: BuilderOption) => {
    const priority = highestPriority(duplicateOptions).priority;
    return lowestPriority(duplicateOptions, priority);
  }).map((option: BuilderOption) => option.id);
}
// Exported Helpers
export function pastDateOptionIds (options: BuilderOption[]) {
  const dateOptions = options.filter(option => option.activeTo !== null);
  const now = dayjs();
  return dateOptions
    .filter(dateOption => dayjs(dateOption.activeTo).diff(now) <= 0)
    .map(dateOption => dateOption.id);
}
export function futureDateOptionIds (options: BuilderOption[]) {
  const dateOptions = options.filter(option => option.activeFrom !== null);
  const now = dayjs();
  return dateOptions
    .filter(dateOption => dayjs(dateOption.activeFrom).diff(now) > 0)
    .map(dateOption => dateOption.id);
}
export function duplicatesToRemove (options: BuilderOption[]) {
  const productIds = options.map(option => option.product.id);
  const duplicateOptionIds = uniq(
    productIds.filter((id, index, array) => array.indexOf(id) !== index)
  );
  const duplicateOptions = [];
  duplicateOptionIds.forEach((id: number) => {
    duplicateOptions.push(options.filter(option => option.product.id === id));
  });
  return getDuplicateIds(duplicateOptions);
}
export function hideOutOfStock (options: BuilderOption[]) {
  return options.filter(option => !(option.product.hideOutOfStock && !option.product.isInStock));
}
