import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import LocalisationState from '../types/LocalisationState'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { Logger } from '@vue-storefront/core/lib/logger'

const applyCurrencySign = (formattedPrice, { currencySign, priceFormat }) => {
  return priceFormat.replace('{sign}', currencySign).replace('{amount}', formattedPrice);
};

const getLocaleSeparators = (defaultLocale) => {
  return {
    decimal: (0.01).toLocaleString(defaultLocale).replace(/[0-9]/g, ''),
    group: (1000).toLocaleString(defaultLocale).replace(/[0-9]/g, '')
  };
};

const replaceSeparators = (formattedPrice, currencySeparators, separators) => {
  if (currencySeparators.decimal) formattedPrice = formattedPrice.replace(separators.decimal, currencySeparators.decimal);
  if (currencySeparators.group) formattedPrice = formattedPrice.replace(separators.group, currencySeparators.group);
  return formattedPrice;
};

const getters: GetterTree<LocalisationState, RootState> = {
  getCountry: (state) => state.country,
  getCountryName: (state, getters) => {
    const country = getters.getAvailableCountries.find(item => item.code === state.country)
    return country && country.name ? country.name : null
  },
  getCurrency: (state) => state.currency,
  getCurrencySymbol: (state) => state.currencySign,
  getAvailableCurrencies: () => config.ayko.localisation.availableCurrencies,
  getAvailableCountries: () => config.ayko.localisation.availableCountries,
  getCurrencyRate: (state, getters) => getters.getCurrencyRates.find(item => item.currency),
  getCurrencyRates: (state, rootState) => rootState.currency_rates.items,
  localisePrice: (state, getters, rootGetters) => {
    return (value, storeView = null, formatWithSymbol = true, convertCurrency = true, localiseString = true) => {
      if (isNaN(value)) {
        return value as string
      }
      const _storeView = storeView || currentStoreView()
      if (!_storeView.i18n) {
        return Number(value).toFixed(2) as string
      }

      let {defaultLocale, currencySign, currencyDecimal, currencyGroup, fractionDigits, priceFormat} = _storeView.i18n

      let currencyRate = 1
      if (convertCurrency) {
        const matchedCurrencyRate = rootGetters['currency_rates'].items
          .find(rate => rate.currency_from === currentStoreView().i18n.baseCurrencyCode && rate.currency_to === state.currency)
        if (matchedCurrencyRate && matchedCurrencyRate.rate) {
          currencyRate = matchedCurrencyRate.rate;
          Logger.debug("Setting currency rate to ':'" + currencyRate + "'", 'LOCALISATION')()
        } else {
          Logger.error("Unable to find matching currency rate for 'from':'" + currentStoreView().i18n.currencyCode + "' 'to':'" + state.currency + "'", 'LOCALISATION')()
        }
      }


      currencySign = getters.getCurrencySymbol ? getters.getCurrencySymbol : currencySign

      let options = {minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits}

      //If using ESW don't convert currency
      const country = rootGetters['esw/getUserCountry'];
      if (config.esw.enabled === true && config.esw.nonEswCountries.includes(country) === false) {
        currencyRate=1;
      }

      if (currencyRate !== 1) {
        value = value * currencyRate
      }

      let localePrice = localiseString ? Math.abs(value).toLocaleString(defaultLocale, options) : Math.abs(value)

      if (currencyDecimal !== '' || currencyGroup !== '') {
        localePrice = replaceSeparators(localePrice, {
          decimal: currencyDecimal,
          group: currencyGroup
        }, getLocaleSeparators(defaultLocale))
      }

      const valueWithSign = applyCurrencySign(localePrice, {
        currencySign: formatWithSymbol ? currencySign : '',
        priceFormat
      })

      return (value >= 0 ? valueWithSign : '-' + valueWithSign) as string
    };
  }
}

export default getters;
