import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import BootsgridVfinanceState from '../types/BootsgridVfinanceState'

import {
  loanAmount,
  monthlyAmount,
  depositAmount,
  getMinSpendLimit,
  maxMonthPlanInterest,
  totalAmountRepayable
} from '../helpers/BootsgridVfinanceCalculation';

const getters: GetterTree<BootsgridVfinanceState, RootState> = {
  getFinanceProducts: state => state.financeProducts,
  getSortedFinanceRates: state => {
    const financeProducts = state.financeProducts;
    return financeProducts.sort((a, b) => (a.apr - b.apr || a.months - b.months));
  },
  getFinanceProductsWithZeroApr: state => state.financeProducts.filter(financeProduct => financeProduct.apr === '0'),
  isFinanceAvailable: (state, getters, rootState, rootGetters) => price => {
    const financeProducts = getters.getFinanceProducts;
    if (!financeProducts.length) {
      return false;
    }
    const coreConfigData = rootGetters['core_config/getConfigItems'];
    if (typeof coreConfigData === 'undefined' || !coreConfigData.length) {
      return false;
    }
    const configFinanceEnabled = rootGetters['core_config/getConfigItem']('payment/vfinance/active');
    const configMinFinanceAmount = rootGetters['core_config/getConfigItem']('payment/vfinance/min_finance_amount');
    const financeEnabled = configFinanceEnabled ? configFinanceEnabled === '1' : false;
    const minFinanceValue = configMinFinanceAmount ? configMinFinanceAmount : 0;
    const minFinanceValueReached = minFinanceValue && price >= parseInt(minFinanceValue);
    return financeEnabled && minFinanceValueReached;
  },
  getMaxMonthPlanInterest: (state, getters) => price => {
    // pass price to get the max month plan interest
    // filter finance products by the min amount allowed
    const availableFinanceProducts = getters.getFinanceProducts.filter(financeProduct => {
      const minSpendLimit = getMinSpendLimit([financeProduct.ribble_min_limit, financeProduct.rb_min_loan]);
      return minSpendLimit <= price;
    });
    return maxMonthPlanInterest(availableFinanceProducts);
  },
  getTotalAmountRepayable: (state, getters) => (price, financeProduct = null, finalPriceInclTax = null) => {
    if (!financeProduct) {
      financeProduct = getters.getMaxMonthPlanInterest(price);
    }
    return totalAmountRepayable(
      finalPriceInclTax || price,
      financeProduct.apr,
      financeProduct.months,
      financeProduct.calc_factor
    );
  },
  getMonthlyAmount: (state, getters) => (price, financeProduct = null, finalPriceInclTax = null) => {
    if (!financeProduct) {
      financeProduct = getters.getMaxMonthPlanInterest(price);
    }
    return monthlyAmount(
      finalPriceInclTax || price,
      financeProduct.apr,
      financeProduct.calc_factor
    );
  },
  getDepositAmount: (state, getters) => (price, financeProduct = null) => {
    if (!financeProduct) {
      financeProduct = getters.getMaxMonthPlanInterest(price);
    }
    return depositAmount(
      price,
      financeProduct.apr
    );
  },
  getLoanAmount: (state, getters) => (price, financeProduct = null) => {
    if (!financeProduct) {
      financeProduct = getters.getMaxMonthPlanInterest(price);
    }
    return loanAmount(
      price,
      financeProduct.apr
    );
  },
  minFinanceAmount: (state, getters) => {
    const v12MinAmounts = getters.getFinanceProducts.map(financeProduct => financeProduct.rb_min_loan);
    const ribbleMinAmounts = getters.getFinanceProducts.map(financeProduct => financeProduct.ribble_min_limit);
    const v12Min = v12MinAmounts.sort((a, b) => a < b)[v12MinAmounts.length - 1];
    const ribbleMin = ribbleMinAmounts.sort((a, b) => a < b)[ribbleMinAmounts.length - 1];
    return Math.max(v12Min, ribbleMin);
  }
}

export default getters
