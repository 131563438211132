import { Route } from 'vue-router'
import { isServer, once } from '@vue-storefront/core/helpers'
import Vue from 'vue'
import dayjs from 'dayjs'
import store from '@vue-storefront/core/store'
import {
  getProduct,
  getProducts
} from '../helpers/product'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import isEqual from 'lodash-es/isEqual'
import isObject from 'lodash-es/isObject'
import reduce from 'lodash-es/reduce'
import isEmpty from 'lodash-es/isEmpty'
import {confirmationEvent} from '../helpers/order-success';

export const isProductUrlInPath = (product, to) => {
  return product && (to.path.includes(product.url_path) || to.path.includes(product.url_key))
}
export const isProductPage = (to) => {
  const product = store.getters['product/getCurrentProduct']
  return product &&
    product.id &&
    (
      isProductUrlInPath(product, to) ||
      isProductUrlInPath(store.getters['product/getOriginalProduct'], to) ||
      isProductUrlInPath(store.getters['product/getParentProduct'], to)
    )
}

export const addProductData = (data, pageType) => {
  const product = store.getters['graphqlProduct/getProduct'];
  if (!product) return;
  const parent = null; //TODO parent info

  let esProductData = {};
  esProductData = {
    productName: product.name,
    productId: product.id,
    productSku: product.sku,
    productParentSku: product.parentSku
      ? product.parentSku
      : parent
      ? parent.sku
      : '',
    productUrlKey: product.url_key,
    productUrlPath: product.url_key,
    productTypeId: `ribble_${pageType}`,
    pageType: pageType,
    isBike: pageType === 'bikebuilder',
    productPrice: store.getters['ayko_localisation/localisePrice'](
      product.price_range.default_price.final_price.value,
      null,
      false
    ),
    productBasePrice: product.price_range.default_price.final_price.value
  };
  data = {
    ...esProductData,
    ...data
  };

  return data;
};

export const isCategoryPage = (to) => {
  const category = store.getters['category-next/getCurrentCategory']
  return category &&
    category.id &&
    to.path.includes(category.url_path)
}

export const addCategoryData = (data) => {
  const category = store.getters['category-next/getCurrentCategory']
  return {
    ...{
      categoryName: category.name,
      categoryId: category.id,
      categoryUrlKey: category.url_key,
      categoryUrlPath: category.url_path
    },
    ...data
  }
}

export const isBlog = (to) => {
  return ['blog', 'blog-posts', 'blog-tags', 'blog-categories'].includes(to.name) ||
    to.path.match(/^\/blog\//)
}

export const isHomepage = (to) => {
  return to.name === 'home' || to.path === '/'
}

export const isMyAccount = (to) => {
  return to.name.match(/my-account/g) || to.path.match(/^\/my-account/)
}

export const isReview = (to) => {
  return to.name.match(/ribbleUpsell/g) || to.path.match(/^\/ribbleUpsell/)
}

export const isMyRibble = (to) => {
  return to.name === 'instagramPost' || to.path.match(/^\/my-ribble/)
}

export const isBikeBuilder = (to) => {
  return to.name === 'sectionDetail' || to.path.match(/\/.+\/build\//)
}

export const addBikeBuilderData = (data) => {
  return addProductData(data, 'bikebuilder')
}

export const isCmsPage = (to) => {
  return to.name === 'cms-page' ||
    // @ts-ignore
    (store.state.cmsPage && store.state.cmsPage.current && to.path.includes(store.state.cmsPage.current.identifier))
}

export const isOrderSuccess = (to) => {
  return to.name === 'external-thank-you' || to.path.match(/^\/order-success/)
}

export const addBreadCrumbs = (pageType, crumbs, data) => {
  if (crumbs && crumbs.length && Array.isArray(crumbs)) {
    return {
      ...{
        breadCrumbs: crumbs.map(crumb => crumb.name).join('|'),
        top_category: crumbs[1] ? crumbs[1].name : crumbs[0].name,
        bottom_category: crumbs.length > 1 ? crumbs[(crumbs.length - 1)].name : crumbs[0].name,
        pageGrouping: pageType + ' - ' + (crumbs[1] ? crumbs[1].name : crumbs[0].name)
      },
      ...data
    }
  }
  return data
}

export const matchMethods = {
  'home': isHomepage,
  'account': isMyAccount,
  'my-ribble': isMyRibble,
  'blog': isBlog,
  'review': isReview,
  'bikebuilder': isBikeBuilder,
  'category': isCategoryPage,
  'product': isProductPage,
  'cms-page': isCmsPage,
  'order-success': isOrderSuccess
}

export const matchPageType = (to) => {
  for (const pageType in matchMethods) {
    if (matchMethods[pageType](to)) {
      return pageType
    }
  }
  return null
}

export const addEntityData = (pageType, data) => {
  let crumbs = store.getters['breadcrumbs/getBreadcrumbsRoutes']
  switch (pageType) {
    case 'product':
      crumbs = store.getters['product/getCurrentProduct'].breadcrumbs
      if (crumbs) {
        if (typeof crumbs === 'string') {
          // @ts-ignore
          crumbs = crumbs.replace(/<[^>]+>/g, '')
            .trim()
            .split(/\r?\n/g)
            .map((item) => {
              return { name: item.trim() }
            })
            .filter((item) => {
              return item.name != null && item.name !== ''
            })
        }
      } else {
        crumbs = store.getters['breadcrumbs/getBreadcrumbsRoutes']
      }
      data = addProductData(data, pageType)
      break
    case 'category':
      data = addCategoryData(data)
      break
    case 'bikebuilder':
      data = addBikeBuilderData(data)
      break
    case 'review':
      data = addProductData(data, pageType)
      break
  }

  data = addBreadCrumbs(pageType, crumbs, data)

  return data
}

export const addCustomerData = (data) => {
  if (store.state.user.current && store.state.user.current.id) {
    data = {
      ...{
        customerId: store.state.user.current.id,
        customerEmail: store.state.user.current.email,
        customerFirstName: store.state.user.current.firstname,
        customerLastName: store.state.user.current.lastname,
        customerGroupId: store.state.user.current.group_id,
        customerGroupCode: 'unavailable in VSF'
      },
      ...data
    }
  }
  return data
}

export const addLocalisationData = (data) => {
  data = {
    ...{
      // @ts-ignore
      country: store.state.ayko_localisation.country,
      // @ts-ignore
      currency: store.state.ayko_localisation.currency,
      // @ts-ignore
      currencySign: store.state.ayko_localisation.currencySign
    },
    ...data
  }
  return data
}
export const difference = (object, base) => {
  return reduce(
    object,
    (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value
      }
      return result
    },
    {}
  )
}

export function trackProducts (products, category = false) {
  Vue.gtm.trackEvent({
    event: 'productImpression',
    category: category || products[0].category,
    ecommerce: {
      // @ts-ignore
      currencyCode: store.state.ayko_localisation.currency,
      impressions: products
    }
  })
}

export const trackEvents = (pageType) => {
  switch (pageType) {
    case 'product':
      const data = getProduct(store.getters['product/getParentProduct'] || store.getters['product/getCurrentProduct'])
      trackProducts([data])
      break
    case 'category':
      const category = store.getters['category-next/getCurrentCategory']
      const products = store.getters['category-next/getCategoryProducts']
      trackProducts(getProducts(products), category.name)
      break
    case 'order-success':
      EventBus.$on('session-after-started', () => {
        store.dispatch(
          'user/getOrdersHistory',
          { refresh: true }
        ).then(() => {
          if (store.state.user.orders_history && store.state.user.orders_history.items && store.state.user.orders_history.items.length) {
            const order = store.state.user.orders_history.items.sort((a, b) => b.date - a.date).find(e => true)
            confirmationEvent(order)
          }
        })
      })
      break
  }
}

export async function afterEach (to: Route, from: Route) {
  if (!isServer) {
    const pageType = matchPageType(to)
    let data = {
      event: 'page',
      dateTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      newDataLayer: true,
      customerLoggedIn: store.getters['user/isLoggedIn'],
      pageType
    }
    data = addEntityData(pageType, data)
    data = addLocalisationData(data)
    data = addCustomerData(data)
    trackEvents(pageType)
    Vue.gtm === null || Vue.gtm === void 0 ? void 0 : Vue.gtm.trackEvent(data)
    EventBus.$on('session-after-started', () => {
      // Check if there is new and different customer data after session start
      if (isEmpty(difference(data, addCustomerData({})))) {
        Vue.gtm === null || Vue.gtm === void 0 ? void 0 : Vue.gtm.trackEvent({...addCustomerData({}), ...{event: ''}})
      }
    })
  }
}
