import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations';
import getters from './getters';
import RootState from '@vue-storefront/core/types/RootState';
import CurrencyRatesState from '../types/CurrencyRatesState';

export const currencyRatesStore: Module<CurrencyRatesState, RootState> = {
  namespaced: true,
  state: {
    items: []
  },
  actions,
  mutations,
  getters
}
