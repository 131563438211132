import { duplicatesToRemove, futureDateOptionIds } from '../../../bikebuilder/helpers/optionHelpers';
import remove from 'lodash-es/remove'
import { GetterTree } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';
import CartBuildState from '../../types/CartBuildState';

const getters: GetterTree<CartBuildState, RootState> = {
  sectionGroups: state => {
    if (state.cartSectionGroups) {
      const sectionGroups = [...state.cartSectionGroups];
      return sectionGroups.sort((a, b) => (a.position > b.position ? 1 : -1));
    }
    return null;
  },
  sections: state => {
    if (state.cartSections) {
      const sections = [...state.cartSections];
      return sections.sort((a, b) => (a.position > b.position ? 1 : -1));
    }
    return null;
  },
  options: state => {
    if (state.cartOptions) {
      const options = [...state.cartOptions];
      const futureDateIds = futureDateOptionIds(options);

      if (futureDateIds.length) {
        const noFutureDates = remove(
          options,
          option => !futureDateIds.includes(option.id)
        );

        const removeDuplicates = duplicatesToRemove(noFutureDates);

        return remove(
          noFutureDates,
          option => !removeDuplicates.includes(option.id)
        );
      }

      return state.cartOptions;
    }
    return [];
  },
  bike: state => {
    return state.cartBike;
  },
  product: state => {
    return state.cartProduct;
  },
  getOptionsByParentId: (state) => (parentId: number) => {
    return state.cartSelectedOptions
      ? state.cartSelectedOptions.filter(
        selectedOption => selectedOption.option && selectedOption.option.product.parentId === parentId
      )
      : [];
  },
  getOptionsBySectionId: state => sectionId => {
    return state.cartSelectedOptions
      ? state.cartSelectedOptions.filter(
        selectedOption => selectedOption.option && selectedOption.option.sectionId === sectionId
      )
      : [];
  },
  getCartOptionById: state => id => {
    if (!state.cartOptions) {
      return null;
    }
    return state.cartOptions.find(option => {
      return option.id.toString() === id.toString();
    });
  },
  selectedOptions: state => {
    return state.cartSelectedOptions;
  }
};

export default getters;
