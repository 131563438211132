import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import RootState from 'core/types/RootState';
import SchemeState from '../../types/SchemeState';

export const scheme: Module<SchemeState, RootState> = {
  namespaced: true,
  state: {
    available_schemes: [],
    generic_information: '',
    saved_scheme: null
  },
  getters,
  actions,
  mutations
};
