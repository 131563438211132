import Vue from 'vue';
import {getProducts} from './product';
import {once} from '@vue-storefront/core/helpers';

export const confirmationEvent = (order) => {
  once('__VUE_EXTEND_GTM_ORDER_SUCCESS_' + order.increment_id + '__', () => {
    Vue.gtm.trackEvent({
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': order.increment_id,
            'affiliation': order ? order.store_name : '',
            'revenue': order.base_grand_total,
            'tax': order.tax_amount,
            'shipping': order.base_shipping_incl_tax,
            'coupon': ''
          },
          'products': getProducts(order.items),
          'currencyCode': order.order_currency_code
        }
      }
    })
    let total = order.grand_total;
    if("undefined" !== typeof order.extension_attributes.cycletowork_certificate_amount){
      total = parseFloat(order.grand_total)+parseFloat(order.extension_attributes.cycletowork_certificate_amount);
    }

    Vue.gtm.trackEvent({
      event: 'confirmation',
      transactionId: order.increment_id,
      customerEmail: order.customer_email,
      // this features on the confirmation page and is set to yes if the order contains a bike
      transactionContainsBike: !!order.items.find(item => (item.product_type === "ribble_bikebuilder" || (item.product_type === "simple" && item.sku.includes("RIBBSTK")))),
      // this features on the confirmation page and states the payment method used"
      transactionPayment: order.payment.method,
      // this features on the confirmation page and states the currency used
      transactionCurrency: order.order_currency_code,
      transactionBaseCurrency: order.base_currency_code,
      // transactionPromoCodes: order.base_currency_code,
      transactionShippingAmount: order.shipping_incl_tax,
      transactionTotal: total,
      transactionTax: order.tax_amount,
      transactionBaseShippingAmount: order.base_shipping_incl_tax,
      transactionBaseTotal: order.base_grand_total,
      transactionBaseTax: order.base_tax_amount,
      transactionProducts: getProducts(order.items)
    })
  });
}
