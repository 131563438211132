import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import RootState from '@vue-storefront/core/types/RootState';
import BootsgridVfinanceState from '../types/BootsgridVfinanceState';

export const BootsgridVfinanceStore: Module<BootsgridVfinanceState, RootState> = {
  namespaced: true,
  state: {
    financeProducts: []
  },
  actions,
  getters,
  mutations
}
