import attributes from '../../bikebuilder/helpers/attributes';
import {calculateBikeTax} from './bikeTaxCalc';
import {calculateProductTax} from '@vue-storefront/core/modules/catalog/helpers/taxCalc';

export function productTaxCalculationHandler ({product, taxClasses, taxCountry, taxRegion = '', sourcePriceInclTax, deprecatedPriceFieldsSupport = false, finalPriceInclTax, userGroupId = null, isTaxWithUserGroupIsActive, baseTaxRate}) {
  if (product.type_id === attributes.BIKE_PRODUCT_TYPE) {
    return calculateBikeTax({
      product,
      taxClasses,
      taxCountry,
      taxRegion,
      sourcePriceInclTax,
      deprecatedPriceFieldsSupport,
      finalPriceInclTax,
      userGroupId,
      isTaxWithUserGroupIsActive,
      baseTaxRate
    });
  }
  return calculateProductTax({
    product,
    taxClasses,
    taxCountry,
    taxRegion,
    sourcePriceInclTax,
    deprecatedPriceFieldsSupport,
    finalPriceInclTax,
    userGroupId,
    isTaxWithUserGroupIsActive,
    baseTaxRate
  });
}
