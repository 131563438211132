import { extendStore } from '@vue-storefront/core/helpers';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import * as types from '@vue-storefront/core/modules/catalog/store/product/mutation-types';
import { setBikeProductOptionsAsync } from './helpers/productOptions';
import { setBikeFinalPrice } from './helpers/addToCart';

const actions = {
  setBikeOptions (context, { bikeOptions, product }) {
    if (bikeOptions) {
      // TODO: this causes some kind of recurrency error
      context.commit(
        types.PRODUCT_SET_CURRENT,
        Object.assign({}, product, {
          product_option: setBikeProductOptionsAsync(context, {
            bikeOptions: bikeOptions
          })
        })
      );
    }
  },
  async setBikePrice ({ rootState, dispatch, rootGetters }, {product, price, userGroupId, isTaxWithUserGroupIsActive}) {
    const {
      defaultRegion,
      finalPriceIncludesTax,
      deprecatedPriceFieldsSupport
    } = rootState.storeView.tax;
    const sourcePriceIncludesTax = true;

    const defaultCountry = rootGetters['ayko_localisation/getCountry'];

    const tcs = await dispatch('tax/list', {}, {root: true});
    setBikeFinalPrice(
      product,
      price,
      tcs.items,
      defaultCountry,
      defaultRegion,
      finalPriceIncludesTax,
      sourcePriceIncludesTax,
      userGroupId,
      isTaxWithUserGroupIsActive,
      deprecatedPriceFieldsSupport
    );
  }
};
const productModule = {
  actions
};

export const BikeProduct: StorefrontModule = function () {
  extendStore('product', productModule);
};
