import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations';
import RootState from '@vue-storefront/core/types/RootState';
import EstimateDeliveryState from '../types/EstimateDeliveryState';

export const estimateDeliveryStore: Module<EstimateDeliveryState, RootState> = {
  namespaced: true,
  state: {
    items: []
  },
  actions,
  mutations
}
