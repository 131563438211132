import { Module } from 'vuex'
import { Wordpress } from '../types/Wordpress';
import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'

export const module: Module<Wordpress, any> = {
  namespaced: true,
  state: {
    posts: [],
    latestPosts: [],
    postsPageInfo: [],
    categories: [],
    menuCategories: [],
    currentPost: null,
    currentCategory: null,
    currentTag: null
  },
  mutations,
  actions,
  getters
}
