import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'

export const createLoadBootsgridVfinanceQuery = (product_id) => {
  let query = new SearchQuery()

  if (product_id) {
    query = query.applyFilter({key: 'product_id', value: {'eq': product_id}})
  }

  return query
}
