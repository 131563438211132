import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import RootState from 'core/types/RootState';
import EposState from '../types/EposState';

// NOTE: posData is not required currently as we check via the user agent string
// once fully tested we can remove the actions and mutations if not required

export const EposStore: Module<EposState, RootState> = {
  namespaced: true,

  state: {
    posData: []
  },

  mutations,
  actions,
  getters
}
