import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import { Store } from 'vuex'
import {getProduct, getProducts} from '../helpers/product'
import {confirmationEvent} from '../helpers/order-success';
import * as orderTypes from '@vue-storefront/core/modules/order/store/mutation-types'

export function afterRegistration (config, store: Store<any>) {
  // @ts-ignore
  const GTM: typeof VueGtm = (Vue as any).gtm

  store.subscribe(({ type, payload }, state) => {
    // Adding/updating a Product to a Shopping Cart
    if (type === 'cart/cart/ADD' || type === 'cart/cart/UPD') {
      GTM.trackEvent({
        event: 'addToCart',
        ecommerce: {
          currencyCode: store.getters['ayko_localisation/getCurrency'],
          add: {
            products: [getProduct(payload.product)]
          }
        }
      });
    }

    // Removing a Product from a Shopping Cart
    if (type === 'cart/cart/DEL') {
      GTM.trackEvent({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [getProduct(payload.product)]
          }
        }
      });
    }

    // Measuring Views of Product Details
    if (type === 'product/product/SET_PRODUCT_CURRENT') {
      GTM.trackEvent({
        ecommerce: {
          detail: {
            'actionField': { 'list': '' }, // 'detail' actions have an optional list property.
            'products': [getProduct(payload)]
          }
        }
      });
    }

    // Measuring Purchases
    if (type === 'order/' + orderTypes.ORDER_LAST_ORDER_WITH_CONFIRMATION && payload.order) {
      const orderId = payload.order.increment_id
      const orderHistory = state.user.orders_history
      const order = state.user.orders_history ? orderHistory.items.find((order) => order['increment_id'].toString() === orderId) : null
      confirmationEvent(order)
    }
  })
}
