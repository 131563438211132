// Source https://github.com/vuestorefront/vue-storefront/pull/3650/files
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { isServer } from '@vue-storefront/core/helpers'
import { ClearCacheStore } from './store'
// import { connect as websocket } from './lib/websocket'
import { checkVersion } from './lib/versioning'

export const ClearCache: StorefrontModule = function ({store}) {
  store.registerModule('clear-cache', ClearCacheStore)
  const config = require('config')

  if (!isServer) {
    if (config.clearCache && config.clearCache.enabled) {
      // if (config.clearCache.websocket.enabled) {
      //   websocket()
      // }
      if (config.clearCache.version.enabled) {
        checkVersion()
      }
    }
  }
}
