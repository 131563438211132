import axios from 'axios'
import config from 'config'
import { cleanUrl } from '../../../themes/ribble/helpers'
import {
  currentStoreView, localizedDispatcherRoute, localizedDispatcherRouteName, localizedRoute,
  localizedRoutePath,
  removeStoreCodeFromRoute
} from '@vue-storefront/core/lib/multistore'
import { Payload } from 'vsf-mapping-fallback'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import { isServer } from '@vue-storefront/core/helpers'
import { router } from '@vue-storefront/core/app'
import { AsyncDataLoader } from '@vue-storefront/core/lib/async-data-loader'

export default function pageBuilderQuery(site) {
  return {
    query: `
      query ($site: [String]) {
        pageBuilderEntries(site: $site) {
          ... on pageBuilder_default_Entry {
            uri
          }
        }
      }
    `,
    variables: {
      site
    }
  };
}

export async function fetchCmsData(storeCode) {
  const site = storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
  const query = pageBuilderQuery(site);
  try {
    const res = await axios.post(config.craftcmsUrl, query);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with graphql api');
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const forRedirect = async ({ dispatch }, { url, params }: Payload) => {
  const urlForCraft = cleanUrl(url);
  const { storeCode, appendStoreCode, storeId } = currentStoreView()
  url = (removeStoreCodeFromRoute(url.startsWith('/') ? url.slice(1) : url) as string)
  url = url.replace(/\/$/, '')
  try {
    let query = new SearchQuery()
    query.applyFilter({ key: 'store_id', value: { 'eq': storeId } })
    query.applyFilter({ key: 'request_path', value: { 'eq': url } })
    const resp = await quickSearchByQuery({
      query,
      start: 0,
      size: 1,
      entityType: 'ayko_url_rewrite',
      sort: 'is_autogenerated:desc,url_rewrite_id:desc',
      excludeFields: null,
      includeFields: [ 'target_path', 'entity_type', 'redirect_type', '_entity_id' ]
    })
    if (resp && resp.items && resp.items.length) {
      switch (resp.items[0].entity_type) {
        case 'product':
          const productQuery = new SearchQuery()
          const productSlug = url.split('/').reverse()[0]
          productQuery.applyFilter({key: 'url_path', value: {'eq': productSlug}})
          const products = await dispatch('product/list', { query: productQuery }, { root: true })
          if (products && products.items && products.items.length) {

            const product = products.items[0]

            await dispatch('product/loadProduct', { childSku: params['childSku'] || product.sku, parentSku: product.parentSku }, { root: true })

            const routerData = {
              params: {
                parentSku: (product.type_id === 'ribble_bikebuilder' && product.is_parent_bike) ? product.sku : product.parentSku,
                slug: product.slug,
                childSku: params['childSku'] ? params['childSku'] : product.sku
              },
              'name': localizedDispatcherRouteName(product.type_id + '-product', storeCode, appendStoreCode)
            }

            await dispatch('url/registerMapping', {
              url: localizedDispatcherRoute(product.url_path, storeCode),
              routeData: routerData
            }, { root: true })

            // router.addRoute(localizedRoute(routerData))
            return routerData
          }
          break;
        case 'category':
          const category = await dispatch('category/single', { key: 'id', value: resp.items[0]._entity_id }, { root: true })
          if (category !== null) {

            const routerData = {
              params: {
                'slug': category.slug
              },
              'name': localizedDispatcherRouteName('category', storeCode, appendStoreCode)
            }

            await dispatch('url/registerMapping', {
              url: localizedDispatcherRoute(category.url_path, storeCode),
              routeData: routerData
            }, { root: true })

            // router.addRoute(localizedRoute(routerData))
            return routerData
          }
          break;
        case 'cms-page':
          const cmsPage = await dispatch('cmsPage/single', { value: url, setCurrent: true }, { root: true })
          if (cmsPage !== null) {

            const routerData = {
              name: localizedDispatcherRouteName('cms-page', storeCode, appendStoreCode),
              params: {
                slug: cmsPage.identifier
              }
            }

            await dispatch('url/registerMapping', {
              url: localizedDispatcherRoute(cmsPage.url_path, storeCode),
              routeData: routerData
            }, { root: true })
            // router.addRoute(localizedRoute(routerData))
            return routerData
          }
          break;
        case 'custom':
          // Only redirect when a redirect code supplied.
          if (resp.items[0].redirect_type > 0) {
            if (isServer) { // we use `isServer` helper to be sure that `AsyncDataLoader` is triggered on server side and `context` exists.
              AsyncDataLoader.push({
                execute: async ({context, route}) => {
                  context.server.response.redirect(resp.items[0].redirect_type, localizedRoutePath(resp.items[0].target_path, storeCode))
                }
              })
            } else {
              await router.replace(localizedRoutePath(resp.items[0].target_path, storeCode))
            }
          }
          break;
      }
    } else {
      // fetch craftcms pagebuilder urls
      try {
        const storeCode = currentStoreView().storeCode;
        const craftUrls = await fetchCmsData(storeCode);
        if (craftUrls && craftUrls.pageBuilderEntries.length) {
          const craftUri = craftUrls.pageBuilderEntries.find((x) => x.uri === urlForCraft);
          if (!craftUri) return undefined;

          const routerData = {
            name: localizedDispatcherRouteName(
              'craft-page',
              storeCode,
              appendStoreCode
            ),
            params: {
              uri: urlForCraft
            }
          };

          return routerData;

        }
      } catch (error) {
        console.error(error);
      }
    }
  } catch {
    return undefined
  }
}
