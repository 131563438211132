import {GetterTree} from 'vuex';
import SchemeState from '../../types/SchemeState';
import RootState from '@vue-storefront/core/types/RootState';
import rootStore from '@vue-storefront/core/store';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

const getters: GetterTree<SchemeState, RootState> = {
  isActive: () => rootStore.getters['core_config/getConfigItem']('ribble_ctw/general/is_active') && currentStoreView().storeCode === 'default',
  availableSchemes: state => state.available_schemes,
  savedScheme: state => state.saved_scheme
}

export default getters;
