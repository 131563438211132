import camelCase from 'lodash-es/camelCase'

/**
 * change object keys to camelCase
 */
function toCamelCase (obj: Record<string, any> = {}): Record<string, any> {
  return Object.keys(obj).reduce((accObj, currKey) => {
    accObj[camelCase(currKey)] = obj[currKey]
    return accObj
  }, {})
}

interface AssignPriceParams {
  product: any,
  target: string,
  price: number,
  tax?: number,
  deprecatedPriceFieldsSupport?: boolean
}

function assignPrice ({ product, target, price, tax = 0, deprecatedPriceFieldsSupport = true }: AssignPriceParams): void {
  let priceUpdate = {
    [target]: price,
    [`${target}_tax`]: tax,
    [`${target}_incl_tax`]: price + tax
  };
  if (deprecatedPriceFieldsSupport) {
    /** BEGIN @deprecated - inconsitent naming kept just for the backward compatibility */
    priceUpdate = Object.assign(priceUpdate, toCamelCase(priceUpdate))
    /** END */
  }

  Object.assign(product, priceUpdate);
}

function createSinglePrice (price: number, rate, isPriceInclTax: boolean) {
  const rate_factor = rate && rate.rate ? parseFloat(rate.rate) / 100 : 0;
  const _price = isPriceInclTax ? price / (1 + rate_factor) : price;
  const tax = _price * rate_factor;

  return { price: _price, tax };
}

function getTaxRate (product, taxClasses, taxCountry, taxRegion = '', finalPriceInclTax, sourcePriceInclTax, userGroupId = null, isTaxWithUserGroupIsActive) {
  let taxRate;
  if (product.tax_class_id > 0) {
    let taxClass;
    if (isTaxWithUserGroupIsActive) {
      taxClass = taxClasses.find((el) =>
        el.product_tax_class_ids.indexOf(parseInt(product.tax_class_id)) >= 0 &&
        el.customer_tax_class_ids.indexOf(userGroupId) >= 0
      )
    } else {
      taxClass = taxClasses.find((el) => el.product_tax_class_ids.indexOf(parseInt(product.tax_class_id) >= 0));
    }
    if (taxClass) {
      for (let rate of taxClass.rates) { // TODO: add check for zip code ranges (!)
        if (rate.tax_country_id === taxCountry && (rate.region_name === taxRegion || rate.tax_region_id === 0 || !rate.region_name)) {
          taxRate = rate;
          break;
        }
      }
    }
  }
  return taxRate;
}

export function setBikeFinalPrice (product, price, taxClasses, taxCountry, taxRegion = '', finalPriceInclTax, sourcePriceInclTax, userGroupId, isTaxWithUserGroupIsActive, deprecatedPriceFieldsSupport) {
  const rate = getTaxRate(
    product,
    taxClasses,
    taxCountry,
    taxRegion,
    finalPriceInclTax,
    sourcePriceInclTax,
    userGroupId,
    isTaxWithUserGroupIsActive
  );
  const finalPriceWithTax = createSinglePrice(price, rate, sourcePriceInclTax);
  assignPrice({ product, target: 'final_price', ...finalPriceWithTax, deprecatedPriceFieldsSupport });
}
