function _prepareProductOption() {
  return {
    extension_attributes: {
      custom_options: [],
      configurable_item_options: [],
      bundle_options: [],
      bike_options: [],
      pre_order_date: null,
      has_been_customised: false
    }
  };
}

export function setBikeProductOptionsAsync(context, { bikeOptions }, ) {
  const productOption = _prepareProductOption();
  const dispatchDateRaw = context.rootGetters['buildEstimateDispatch/dispatchDateRaw'];
  const hasBeenCustomised = context.rootGetters['build/hasBeenCustomised'];
  productOption.extension_attributes.bike_options = bikeOptions;
  productOption.extension_attributes.pre_order_date = dispatchDateRaw;
  productOption.extension_attributes.has_been_customised = hasBeenCustomised;
  return productOption;
}
