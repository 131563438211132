import { Wordpress } from '../types/Wordpress';
import { GetterTree } from 'vuex';

export const getters: GetterTree<Wordpress, any> = {
  posts (state) {
    return state.posts;
  },
  latestPosts (state) {
    return state.latestPosts;
  },
  postsPageInfo (state) {
    return state.postsPageInfo;
  },
  categories (state) {
    return state.categories;
  },
  menuCategories (state) {
    return state.menuCategories;
  },
  currentPost (state) {
    return state.currentPost;
  },
  currentCategory (state) {
    return state.currentCategory;
  },
  currentTag (state) {
    return state.currentTag;
  }
}
