import config from 'config';
import { ActionTree } from 'vuex';
import * as types from './mutation-types';
import rootStore from '@vue-storefront/core/store';
import { Logger } from '@vue-storefront/core/lib/logger';
import RootState from '@vue-storefront/core/types/RootState';
import BootsgridVfinanceState from '../types/BootsgridVfinanceState';
import { entityKeyName } from '@vue-storefront/core/lib/store/entities';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import { canCache } from '@vue-storefront/core/modules/catalog/helpers/search';
import { isOnline, quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { createLoadBootsgridVfinanceQuery } from '../helpers/createLoadBootsgridVfinanceQuery';

const actions: ActionTree<BootsgridVfinanceState, RootState> = {

  async loadFinanceData ({ dispatch }, skipCache = false) {
    // 1. THIS GETS CALLED FIRST ON PAGE LOAD
    Logger.info('Fetching v12Finance data asynchronously', 'v12Finance')();
    EventBus.$emit('v12Finance-before-load', {
      store: rootStore
    });
    const financeData = await dispatch('single', {
      key: 'financeProducts',
      skipCache
    });
    EventBus.$emitFilter('v12Finance-after-load', {
      store: rootStore,
      financeData
    });

    return financeData;
  },

  async single ({ dispatch, commit }, { key, skipCache = false }) {
    // 2. THIS GETS CALLED SECOND BY loadFinanceData
    const cacheKey = entityKeyName(key);

    return new Promise((resolve, reject) => {
      const benchmarkTime = new Date();
      const cache = StorageManager.get('v12Finance');

      const setupFinanceData = async financeData => {
        commit(types.BOOTSGRID_VFINANCE_UPD_VFINANCE, financeData);
        return financeData;
      };

      const syncFinanceData = () => {
        // 7. IF NOTHING IN CACHE FETCH THE DATA USING LIST ACTION
        return dispatch('list', { product_id: null }).then(result => {
          if (result && result.items) {
            const financeData = result.items;
            const returnFinanceDataNoCacheHelper = financeData => {
              EventBus.$emitFilter('v12Finance-after-single', {
                key,
                financeData
              });
              resolve(setupFinanceData(financeData));
            };
            // 8. THIS GETS RESOLVED FROM THE PARENT PROMISE
            returnFinanceDataNoCacheHelper(financeData);
          } else {
            commit(types.BOOTSGRID_VFINANCE_UPD_VFINANCE, null);
            Logger.info('v12Finance query returned empty result', 'v12Finance', {
              'Search for': key
            })();
            reject(new Error('v12Finance query returned empty result'));
          }
        });
      };

      const getFinanceDataFromCache = () => {
        // 5. THIS GETS THE ITEM IN CACHE
        cache.getItem(cacheKey, (err, res) => {
          // report errors
          if (!skipCache && err) { // IS skipCache CHECK NEEDED? THIS DOESN'T RUN IF skipCache true
            Logger.error(err, 'v12Finance')();
          }
          if (res !== null) {
            Logger.debug(
              'v12Finance:single - result from localForage (for ' +
                cacheKey +
                '),  ms=' +
                (new Date().getTime() - benchmarkTime.getTime()),
              'v12Finance'
            )();

            const returnFinanceDataFromCacheHelper = () => {
              const cachedFinanceData = setupFinanceData(res);
              EventBus.$emitFilter('v12Finance-after-single', {
                key: key,
                financeData: cachedFinanceData
              });
              resolve(cachedFinanceData);
            };
            returnFinanceDataFromCacheHelper();
          } else {
            // 6. IF RESPONSE IS NULL NOTHING IN CACHE SO SYNC
            syncFinanceData();
          }
        });
      };

      if (!skipCache) {
        // 3. THIS GETS RUN IF skipCache OFF
        getFinanceDataFromCache();
      } else {
        if (!isOnline()) {
          skipCache = false; // TODOD you can't set skipCache??
        }
        syncFinanceData();
      }
    });
  },

  async list ({ dispatch }, {
    start = 0,
    size = 20,
    sort = '',
    product_id = null,
    excludeFields = null,
    includeFields = null,
    entityType = 'v12_finance_products'
  }) {
    const query = createLoadBootsgridVfinanceQuery(product_id);
    const searchResult = await dispatch('findFinanceData', {
      query,
      start,
      size,
      sort,
      entityType,
      excludeFields,
      includeFields
    });
    EventBus.$emit('v12Finance-after-list', {
      query,
      start,
      size,
      sort,
      entityType,
      result: searchResult
    });

    return searchResult;
  },

  async findFinanceData ({ dispatch }, {
    query,
    start = 0,
    size = 1,
    entityType = 'v12Finance',
    sort = '',
    cacheByKey = 'financeProducts',
    excludeFields = null,
    includeFields = null
  }) {
    const isCacheable = canCache({ includeFields, excludeFields });
    // const {excluded, included} = getOptimizedFields({excludeFields, includeFields})
    const response = await quickSearchByQuery({
      query,
      start,
      size,
      entityType,
      sort,
      excludeFields,
      includeFields
    });
    // 10. CONFIGURE THE ES FINANCE DATA
    return dispatch('configureLoadedFinance', {
      financeData: response,
      isCacheable,
      cacheByKey
    });
  },

  async configureLoadedFinance ({ dispatch }, {
    financeData,
    isCacheable,
    cacheByKey
  }) {
    if (isCacheable) {
      // 10. CONFIGURE THE ES FINANCE DATA TO SEND TO CACHE
      // store cache only for full loads
      const financeProducts = financeData.items;
      await dispatch('storeFinanceToCache', { financeProducts, cacheByKey });
    }

    return financeData;
  },

  storeFinanceToCache (context, { financeProducts, cacheByKey }) {
    // 11. STORE THE FINANCE DATA IN CACHE
    // CONTEXT IS REQUIRED, PASS FINANCE PRODUCTS AS OBJECT
    const cacheKey = entityKeyName(cacheByKey);
    const cache = StorageManager.get('v12Finance');
    cache
      .setItem(
        cacheKey,
        financeProducts,
        null,
        config.financeData.disablePersistentFinanceCache
      )
      .catch(err => {
        Logger.error('Cannot store cache for ' + cacheKey, err)();
        if (
          err.name === 'QuotaExceededError' ||
          err.name === 'NS_ERROR_DOM_QUOTA_REACHED'
        ) {
          // quota exceeded error
          cache.clear(); // clear financeData cache if quota exceeded
        }
      });
  }
};

export default actions
