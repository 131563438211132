import { Wordpress } from '../types/Wordpress'
import { ActionTree } from 'vuex';
import * as types from './mutation-types'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import SearchAdapter from '../lib/searchAdapter'
import config from 'config';

const searchAdapter = new SearchAdapter()

// it's a good practice for all actions to return Promises with effect of their execution
export const actions: ActionTree<Wordpress, any> = {
  // if you want to use cache in your module you can load cached data like this
  loadPost ({ commit }, { slug }) {
    return new Promise((resolve, reject) => {
      const query = `query GET_POST($slug: String!) { postBy(slug: $slug) {
          title(format: RENDERED)
          date
          uri
          databaseId
          content(format: RENDERED)
          featuredImage { node { sourceUrl } }
          author {
            node {
              name
              uri
            }
          }
          comments {
            nodes {
              id
              parentId
              approved
              date
              content(format: RENDERED)
              author {
                node { name }
              }
            }
          }
          terms {
            nodes {
              uri
              id
              name
              ... on Category {
                name
                uri
                categoryId
              }
              ... on Tag {
                id
                name
                tagId
              }
            }
          }
        }
      }`;

      const Request = {
        storeView: currentStoreView(),
        query: query,
        queryVars: { slug }
      }
      searchAdapter.search(Request).then((resp) => {
        if (typeof resp.data === 'undefined') {
          reject('Data undefined');
          return;
        }
        commit(types.SET_CURRENTPOST, resp.data.postBy)
        resolve(resp.data.postBy)
      }, error => {
        reject(error)
      })
    })
  },
  loadLatestPosts ({ commit, state }, { first }) {
    return new Promise((resolve, reject) => {
      const query = `query GET_POSTS($first: Int) {
        posts(first: $first) {
            nodes {
              id
              title(format: RENDERED)
              date
              slug
              excerpt(format: RENDERED)
              featuredImage { node { sourceUrl altText } }
          }
        }
      }`;

      const Request = {
        storeView: currentStoreView(),
        query: query,
        queryVars: { first }
      }

      searchAdapter.search(Request).then((resp) => {
        if (!resp.data) {
          resolve([]);
          return;
        }
        let posts = resp.data.posts;
        commit(types.SET_LATEST_POSTS, posts.nodes);
        resolve(posts);
      }, error => {
        reject(error);
      })
    })
  },
  loadPosts ({ commit, state }, { position, direction, filterBy, filter }) {
    if (!position) {
      position = '""';
    }
    const first = direction === 'next' ? config.wordpress.postsPerPage : null;
    const last = direction === 'prev' ? config.wordpress.postsPerPage : null;
    const before = direction === 'prev' ? position : '""';
    const after = direction === 'next' ? position : '""';

    let queryTypes = '$first: Int, $last: Int, $before: String, $after: String';
    let where = '';
    if (filterBy && filter) {
      if (filterBy === 'tag') {
        where = ', where: { tagSlugIn: [$filter] }';
        queryTypes = queryTypes + ', $filter: String';
      } else if (filterBy === 'category') {
        where = ', where: { categoryIn: [$filter] }';
        queryTypes = queryTypes + ', $filter: ID';
      }
    }
    const postQuery = 'first: $first, last: $last, before: $before, after: $after' + where;

    return new Promise((resolve, reject) => {
      const query = `query GET_POSTS(${queryTypes}) {
        posts(${postQuery}) {
          pageInfo {
            startCursor
            hasPreviousPage
            hasNextPage
            endCursor
          }
          nodes {
            id
            title(format: RENDERED)
            date
            slug
            excerpt(format: RENDERED)
            featuredImage { node { sourceUrl altText } }
          }
        }
     }`;

      const Request = {
        storeView: currentStoreView(),
        query: query,
        queryVars: { first, last, before, after, filter }
      }

      searchAdapter.search(Request).then((resp) => {
        if (!resp.data) {
          resolve([]);
          return;
        }
        let posts = resp.data.posts;
        commit(types.SET_POSTS, posts.nodes);
        commit(types.SET_POSTS_PAGE_INFO, posts.pageInfo);
        resolve(posts);
      }, error => {
        reject(error);
      })
    })
  },
  loadMenuCategories ({ commit, state }) {
    return new Promise((resolve, reject) => {
      const query = `query GETMENUCATEGORIES {
          menus(where: {slug: ${config.wordpress.menuCategory}}) {
            nodes {
              menuItems {
                nodes {
                  id
                  path
                  label
                }
              }
            }
          }
        }`;

      const Request = {
        storeView: currentStoreView(),
        query: query
      }

      searchAdapter.search(Request).then((resp) => {
        const data = resp.data;
        const categories = data ? data.menus.nodes[0].menuItems.nodes : [];
        commit(types.SET_MENU_CATEGORIES, categories)
        resolve(categories)
      }, error => {
        reject(error)
      })
    });
  },
  loadCategoryBySlug ({ commit }, { slug }) {
    return new Promise((resolve, reject) => {
      const query = `query GET_CATEGORY {
        categories(where: {slug: ${slug}}) {
          nodes {
            id
            description
            name
            slug
            termTaxonomyId
            children(first: 8) {
               edges {
                  node {
                    id
                    name
                    uri
                  }
               }
            }
          }
          posts(first: ${config.wordpress.postsPerPage}) {
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            nodes {
              id
              date
              slug
              excerpt(format: RENDERED)
              title(format: RENDERED)
              featuredImage { node { sourceUrl } }
            }
          }
        }
      }`;

      const Request = {
        storeView: currentStoreView(),
        query: query,
        queryVars: {}
      };

      searchAdapter.search(Request).then((resp) => {
        commit(types.SET_CURRENTCATEGORY, resp.data.categories.nodes[0])
        commit(types.SET_POSTS, resp.data.categories.nodes[0].posts.nodes);
        commit(types.SET_POSTS_PAGE_INFO, resp.data.categories.nodes[0].posts.pageInfo);
        resolve(resp.data.categories.nodes[0])
      }, error => {
        reject(error)
      })
    })
  },
  loadTagBySlug ({ commit }, { slug }) {
    return new Promise((resolve, reject) => {
      const query = `query GET_TAG {
        tag(id: "${slug}", idType: SLUG) {
          id
          name
          description
          slug
          termTaxonomyId
          posts(first: ${config.wordpress.postsPerPage}) {
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            nodes {
              id
              date
              slug
              excerpt(format: RENDERED)
              title(format: RENDERED)
              featuredImage { node { sourceUrl } }
            }
          }
        }
      }`;

      const Request = {
        storeView: currentStoreView(),
        query: query,
        queryVars: { }
      };

      searchAdapter.search(Request).then((resp) => {
        commit(types.SET_CURRENTTAG, resp.data.tag);
        commit(types.SET_POSTS, resp.data.tag.posts.nodes);
        commit(types.SET_POSTS_PAGE_INFO, resp.data.tag.posts.pageInfo);
        resolve(resp.data.tag);
      }, error => {
        reject(error);
      })
    })
  }
}
