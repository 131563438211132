import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from 'core/types/RootState'
import { PacPageState } from '../types/PacPageState'

export const PacPageModule: Module<PacPageState, RootState> = {
  namespaced: true,
  state: {
    categories: [],
    bestsellerProducts: {},
    landingCatIds: [],
    contentCatIds: []
  },
  actions,
  getters,
  mutations
}
