import { MutationTree } from 'vuex'
import { PacPageState } from '../types/PacPageState';
import * as types from './mutation-types'

const mutations: MutationTree<PacPageState> = {
  [types.PAC_UPD_CATEGORIES] (state, categories) {
    state.categories = categories
  },
  [types.PAC_UPD_LANDING_CATEGORIES] (state, landingCatIds) {
    state.landingCatIds = landingCatIds
  },
  [types.PAC_UPD_CONTENT_CATEGORIES] (state, contentCatIds) {
    state.contentCatIds = contentCatIds
  },
  [types.PAC_UPD_BESTSELLERS] (state, bestsellers) {
    state.bestsellerProducts = bestsellers
  }
}

export default mutations
