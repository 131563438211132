import {currentStoreView} from '@vue-storefront/core/lib/multistore';
import {isServer} from '@vue-storefront/core/helpers';
import rootStore from '@vue-storefront/core/store';

export function currentDeliveryCountry () {
  const storeView = currentStoreView();
  const defaultCountry = storeView.tax.defaultCountry;
  let selectedCountry = defaultCountry;
  if (!isServer) {
    selectedCountry = rootStore.getters['ayko_localisation/getCountry'];
  }

  return selectedCountry;
}
