import * as types from './mutation-types';
import {MutationTree} from 'vuex';
import CartBuildState from '../../types/CartBuildState';

const mutations: MutationTree<CartBuildState> = {
  [types.SET_BIKE] (state, bike) {
    state.cartBike = bike;
  },
  [types.SET_PRODUCT] (state, product) {
    state.cartProduct = product;
  },
  [types.SET_SECTION_GROUPS] (state, sectionGroups) {
    state.cartSectionGroups = sectionGroups;
  },
  [types.SET_SECTIONS] (state, sections) {
    state.cartSections = sections;
  },
  [types.SET_OPTIONS] (state, options) {
    state.cartOptions = options;
  },
  [types.SET_SELECTED_OPTIONS] (state, options) {
    state.cartSelectedOptions = options;
  },
  [types.SET_ERROR] (state, error) {
    state.error = error;
  }
};

export default mutations;
