import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types';
import productsEquals from '../../helpers/productEquals';
import { MutationTree } from 'vuex';
import CartState from '@vue-storefront/core/modules/cart/types/CartState';

const mutations: MutationTree<CartState> = {
  [types.CART_ADD_ITEM] (state, {product}) {
    const record = state.cartItems.find(p => productsEquals(p, product));
    if (!record) {
      let item = {
        ...product,
        qty: parseInt(product.qty ? product.qty : 1)
      };
      EventBus.$emit('cart-before-add', {product: item});
      state.cartItems.push(item);
    } else {
      EventBus.$emit('cart-before-update', {product: record});
      record.qty += parseInt(product.qty ? product.qty : 1);
    }
  },
  [types.CART_DEL_ITEM] (state, {product, removeByParentSku = true}) {
    EventBus.$emit('cart-before-delete', {items: state.cartItems});
    state.cartItems = state.cartItems.filter(
      p =>
        !productsEquals(p, product) &&
        (p.parentSku !== product.sku || removeByParentSku === false)
    );
    EventBus.$emit('cart-after-delete', {items: state.cartItems});
  }
}

export default mutations;
