import axios from 'axios';
import { extendStore } from '@vue-storefront/core/helpers';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog/hooks';
import { Logger } from '@vue-storefront/core/lib/logger';
import config from 'config'
import { doPlatformPricesSync } from '@vue-storefront/core/modules/catalog/helpers';
import { productTaxCalculationHandler } from './helpers/bikeTaxHandler';
import { eswProductQuery } from '../../themes/ribble/store/magento-queries/esw';
import { fetchEswGraphqlData } from '../../themes/ribble/helpers';

async function eswPriceFetch(products, recalculatedProducts, country, currency) {
  const eswPayload = products.flatMap(product => {
    return [
      {
        sku: product.sku,
        type: 'final_price_incl_tax',
        price: product.final_price
      },
      {
        sku: product.sku,
        type: 'original_price_incl_tax',
        price: product.price
      },
      {
        sku: product.sku,
        type: 'special_price_incl_tax',
        price: product.special_price || 0
      }
    ];
  });
  const query = eswProductQuery(eswPayload);
  const data = await fetchEswGraphqlData(query, country, currency);
  const eswPrices = data && data.eShopWorldPriceConvert ? data.eShopWorldPriceConvert : [];

  return recalculatedProducts.map(product => {
    const eswProduct = eswPrices.filter(eswProduct => eswProduct.sku === product.sku);
    const final_price_incl_tax = eswProduct.find(eswProduct => eswProduct.type === 'final_price_incl_tax');
    const original_price_incl_tax = eswProduct.find(eswProduct => eswProduct.type === 'original_price_incl_tax');
    const special_price_incl_tax = eswProduct.find(eswProduct => eswProduct.type === 'special_price_incl_tax');

    product['final_price_incl_tax'] = final_price_incl_tax ? final_price_incl_tax.price : 0;
    product['original_price_incl_tax'] = original_price_incl_tax ? original_price_incl_tax.price : 0;
    product['special_price_incl_tax'] = special_price_incl_tax ? special_price_incl_tax.price : 0;
    return product;
  });
}

const actions = {
  async calculateTaxes ({ dispatch, getters, rootState, rootGetters }, { products }) {
    const country = rootGetters['esw/getUserCountry'];
    const currency = rootGetters['esw/getUserCurrency'];
    const mutatedProducts = catalogHooksExecutors.beforeTaxesCalculated(products)

    if (config.tax.calculateServerSide) {
      Logger.debug('Taxes calculated server side, skipping')()
      return doPlatformPricesSync(mutatedProducts)
    }

    const tcs = await dispatch('list', {})
    const {
      defaultCountry,
      defaultRegion,
      sourcePriceIncludesTax,
      finalPriceIncludesTax,
      deprecatedPriceFieldsSupport,
      baseTaxRate
    } = rootState.storeView.tax

    const localisedCountry = rootState.ayko_localisation.country ? rootState.ayko_localisation.country : defaultCountry

    const recalculatedProducts = mutatedProducts.map(product =>
      productTaxCalculationHandler({
        product,
        taxClasses: tcs.items,
        taxCountry: localisedCountry,
        taxRegion: defaultRegion,
        finalPriceInclTax: finalPriceIncludesTax,
        sourcePriceInclTax: sourcePriceIncludesTax,
        userGroupId: getters.getUserTaxGroupId,
        deprecatedPriceFieldsSupport: deprecatedPriceFieldsSupport,
        isTaxWithUserGroupIsActive: getters.getIsUserGroupedTaxActive,
        baseTaxRate: parseFloat(baseTaxRate) / 100
      })
    )

      try {
        const eswProducts = await eswPriceFetch(products, recalculatedProducts, country, currency);
        return doPlatformPricesSync(eswProducts);
      } catch (error) {
        console.error(error);
      }

  }
};

const taxModule = {
  actions
};

export const BikeBuilderTax: StorefrontModule = function () {
  extendStore('tax', taxModule);
};
