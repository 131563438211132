import { TaskQueue } from '@vue-storefront/core/lib/sync';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import config from 'config';

/** links to m2 /V1/customers/resetPassword */
const createPassword = async (email: string, newPassword: string, resetToken: string): Promise<boolean> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.users.createPassword_endpoint),
    payload: {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, newPassword, resetToken })
    }
  }).then(({result}) => result);

const checkResetToken = async (customerId: string, resetToken: string): Promise<boolean> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.users.checkResetToken_endpoint + '/' + customerId + '/' + resetToken),
    payload: {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }
  }).then(({result}) => result);

export const CreatePasswordService = {
  createPassword,
  checkResetToken
}
