import { getRibbleUserAgent } from '../helpers';

const getters = {
  posData: state => state.posData,
  getDevice: () => getRibbleUserAgent() ? getRibbleUserAgent().device : null,
  getSku: () => getRibbleUserAgent() ? getRibbleUserAgent().sku : null,
  isCustomised: () => getRibbleUserAgent() ? getRibbleUserAgent().isCustomised === 'customised' : false,
  getStore: () => getRibbleUserAgent() ? getRibbleUserAgent().retailStore : 'web',
  isCheckout: () => getRibbleUserAgent() && getRibbleUserAgent().device === 'checkout',
  isKiosk: () => getRibbleUserAgent() && getRibbleUserAgent().device === 'kiosk',
  isPos: () => getRibbleUserAgent() && getRibbleUserAgent().device === 'pos'
}

export default getters;
