import { ActionTree } from 'vuex';
import { cartHooksExecutors } from '@vue-storefront/core/modules/cart/hooks';
import * as cart_types from '@vue-storefront/core/modules/cart/store/mutation-types';
import {createDiffLog, notifications, validateProduct} from '@vue-storefront/core/modules/cart/helpers';
import prepareProductsToAdd from '../../helpers/prepareProductsToAdd';
import CartState from '@vue-storefront/core/modules/cart/types/CartState';
import RootState from '@vue-storefront/core/types/RootState';
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types';
import {Logger} from '@vue-storefront/core/lib/logger';
import i18n from '@vue-storefront/core/i18n';
import config from 'config';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import productsEquals from '../../helpers/productEquals';
import CartItem from '@vue-storefront/core/modules/cart/types/CartItem';
import { CartService } from '@vue-storefront/core/data-resolver';

const actions: ActionTree<CartState, RootState> = {
  async mergeClientItem ({ dispatch }, { clientItem, serverItems, forceClientState, dryRun }) {
    const serverItem = serverItems.find(itm => productsEquals(itm, clientItem))
    const diffLog = await dispatch('synchronizeServerItem', { serverItem, clientItem, forceClientState, dryRun })

    if (!diffLog.isEmpty()) return diffLog

    Logger.info('Server and client item with SKU ' + clientItem.sku + ' synced. Updating cart.', 'cart', 'cart')()
    if (!dryRun) {
      const product = {
        sku: clientItem.sku,
        server_cart_id: serverItem.quote_id,
        server_item_id: serverItem.item_id,
        product_option: serverItem.product_option,
        type_id: serverItem.product_type
      }

      await dispatch('updateItem', { product })
    }

    return diffLog
  },
  async mergeServerItem ({ dispatch, getters }, { clientItems, serverItem, forceClientState, dryRun }) {
    const diffLog = createDiffLog()
    const clientItem = clientItems.find(itm => productsEquals(itm, serverItem))
    if (clientItem) return diffLog
    Logger.info('No client item for' + serverItem.sku, 'cart')()
    diffLog.pushClientParty({ sku: serverItem.sku, status: 'no-item' })
    if (dryRun) return diffLog

    if (forceClientState) {
      Logger.info('Removing product from cart', 'cart', serverItem)()
      Logger.log('Removing item' + serverItem.sku + serverItem.item_id, 'cart')()
      const cartItem = {
        sku: serverItem.sku,
        item_id: serverItem.item_id,
        quoteId: serverItem.quote_id
      } as any as CartItem

      const resp = await CartService.deleteItem(getters.getCartToken, cartItem)
      return diffLog.pushServerResponse({ status: resp.resultCode, sku: serverItem.sku, result: resp })
    }

    const productToAdd = await dispatch('getProductVariant', { serverItem })

    if (productToAdd) {
      dispatch('addItem', { productToAdd, forceServerSilence: true })
      Logger.debug('Product variant for given serverItem has not found', 'cart', serverItem)()
    }

    return diffLog
  },
  async addItem ({dispatch, commit}, {productToAdd, forceServerSilence = false}) {
    const {cartItem} = cartHooksExecutors.beforeAddToCart({cartItem: productToAdd});
    commit(cart_types.CART_ADDING_ITEM, {isAdding: true});
    const result = await dispatch('addItems', {productsToAdd: prepareProductsToAdd(cartItem), forceServerSilence});
    commit(cart_types.CART_ADDING_ITEM, {isAdding: false});
    cartHooksExecutors.afterAddToCart(result);
    return result;
  },
  async removeItem ({commit, dispatch, getters}, payload) {
    const removeByParentSku = payload.product
      ? !!payload.removeByParentSku &&
      (payload.product.type_id !== 'bundle' ||
        payload.product.type_id !== 'ribble_bikebuilder')
      : true;

    const product = payload.product || payload;
    const {cartItem} = cartHooksExecutors.beforeRemoveFromCart({
      cartItem: product
    });

    commit(cart_types.CART_DEL_ITEM, {product: cartItem, removeByParentSku});

    if (getters.isCartSyncEnabled && cartItem.server_item_id) {
      const diffLog = await dispatch('sync', {forceClientState: true});
      cartHooksExecutors.afterRemoveFromCart(diffLog);
      return diffLog;
    }

    const diffLog = createDiffLog().pushClientParty({
      status: 'no-item',
      sku: product.sku
    });
    cartHooksExecutors.afterRemoveFromCart(diffLog);
    return diffLog;
  },
  async addItems ({ commit, dispatch, getters }, { productsToAdd, forceServerSilence = false }) {
    let productIndex = 0
    const diffLog = createDiffLog()
    for (let product of productsToAdd) {
      const errors = validateProduct(product)
      diffLog.pushNotifications(notifications.createNotifications({ type: 'error', messages: errors }))

      if (errors.length === 0) {
        if (product.type_id === 'ribble_bikebuilder') {
          commit(types.CART_ADD_ITEM, {
            product: { ...product, onlineStockCheckid: 1 }
          })
        } else {
          const {status, onlineCheckTaskId} = await dispatch('checkProductStatus', {product})

          if (status === 'volatile') {
            diffLog.pushNotification(notifications.unsafeQuantity())
          }
          if (status === 'out_of_stock') {
            diffLog.pushNotification(notifications.outOfStock())
          }
          if (status === 'ok' || status === 'volatile') {
            commit(types.CART_ADD_ITEM, {
              product: {...product, onlineStockCheckid: onlineCheckTaskId}
            })
          }
        }
        if (productIndex === (productsToAdd.length - 1) && (!getters.isCartSyncEnabled || forceServerSilence)) {
          diffLog.pushNotification(notifications.productAddedToCart())
        }
        productIndex++
      }
    }
    if (getters.isCartSyncEnabled && getters.isCartConnected && !forceServerSilence) {
      return dispatch('sync', { forceClientState: true })
    }

    return diffLog
  },
  async stockSync ({ dispatch, commit }, stockTask) {
    const product = { sku: stockTask.product_sku }

    const cartItem = await dispatch('getItem', { product })
    if (!cartItem || stockTask.result.code === 'ENOTFOUND') return
    if (cartItem.product_type === 'ribble_bikebuilder') {
      return;
    }

    if (!stockTask.result.is_in_stock) {
      if (!config.stock.allowOutOfStockInCart && !config.cart.synchronize) {
        Logger.log('Removing product from cart' + stockTask.product_sku, 'stock')()
        commit(types.CART_DEL_ITEM, { product: { sku: stockTask.product_sku } }, { root: true })
        return
      }

      dispatch('updateItem', {
        product: { errors: { stock: i18n.t('Out of the stock!') }, sku: stockTask.product_sku, is_in_stock: false }
      })

      return
    }

    dispatch('updateItem', {
      product: { info: { stock: i18n.t('In stock!') }, sku: stockTask.product_sku, is_in_stock: true }
    })
    EventBus.$emit('cart-after-itemchanged', { item: cartItem })
  }
}

export default actions;
