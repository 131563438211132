import { ActionTree } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import RootState from '@vue-storefront/core/types/RootState'
import CoreConfigState from '../types/CoreConfigState'
import * as types from './mutation-types'
import { createLoadCoreConfigQuery } from '../helpers/createLoadCoreConfigQuery'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { isServer } from '@vue-storefront/core/helpers'
import Vue from 'vue'

const actions: ActionTree<CoreConfigState, RootState> = {
  async list ({ commit, state, dispatch }, {path = '', start = 0, size = 4000, entityType = 'ayko_config', sort = '', excludeFields = null, includeFields = null, skipCache = false, updateState = true}) {
    const { query, isCustomized } = createLoadCoreConfigQuery(path)
    let shouldLoadConfigs = skipCache || ((!state.items || state.items.length === 0) || isCustomized)

    if (isServer) Vue.prototype.$cacheTags.add(`core_config`)

    if (shouldLoadConfigs) {
      const resp = await quickSearchByQuery({
        query,
        start,
        size,
        entityType,
        sort,
        excludeFields,
        includeFields
      })

      if (updateState) {
        commit(types.CORE_CONFIG_UPD_CONFIG_PATHS, resp.items)
        EventBus.$emit('ayko-config-after-list', { query: query, sort, size, start, list: resp })
      }

      if (isServer && resp.items && resp.items.length) {
        resp.items.forEach(item => {
          Vue.prototype.$cacheTags.add(`core_config_${item.id}`)
        })
      }
      return resp
    }

    const list = { items: state.items, total: state.items.length }

    if (updateState) {
      EventBus.$emit('ayko-config-after-list', { query: query, sort, size, start, list: list })
    }
    if (isServer && list.items && list.items.length) {
      list.items.forEach(item => {
        Vue.prototype.$cacheTags.add(`core_config_${item.id}`)
      })
    }

    return list
  }
}

export default actions
